import { environment } from '../../../utils/environment'
import { fetcher } from '../../../utils/fetcher'

export type ParentCategory = {
  name: string
  id: number
}

export interface Item {
  category: ParentCategory
  icon: string
  id: number
  name: string
}

export interface Role extends Item {
  description: string
}

export interface Interest extends Item {
  icon_colored: string
}

export interface Category {
  name: string
  id: number
  items: Array<Item>
}

export const fetchRoles = async (): Promise<Category[]> => {
  // FIXME: Remove try/catch, replace with `loaded`(), remove logging the errors
  try {
    const roles = (
      /* FIXME: TODO: api.candao.io deprecation */
      await fetcher(`${environment.CANDAO_API}/api/profession-role`, {
        pagination: {
          page: 1,
          per_page: 999,
        },
      })
    ).profession_roles
    /* FIXME: TODO: api.candao.io deprecation */
    const rolesCategories = await fetcher(`${environment.CANDAO_API}/api/profession-role/categories`)
    const rolesMap: Map<string, Array<Role>> = new Map<string, Array<Role>>()

    rolesCategories.categories.forEach((category: any) => {
      rolesMap.set(category.name, [])
    })

    roles.forEach((role: Role) => {
      rolesMap.set(role.category.name, [...(rolesMap.get(role.category.name) as Array<Role>), role])
    })

    const categories: Array<Category> = []

    for (let [key, value] of rolesMap) {
      categories.push({
        name: key,
        items: value,
        id: -1,
      })
    }

    return categories
  } catch (e) {
    console.info(e)
    return []
  }
}

export const fetchInterests = async (): Promise<Category[]> => {
  try {
    const interests = (
      /* FIXME: TODO: api.candao.io deprecation */
      await fetcher(`${environment.CANDAO_API}/api/interests/list`, {
        pagination: {
          page: 1,
          per_page: 999,
        },
      })
    ).interests
    /* FIXME: TODO: api.candao.io deprecation */
    const interestsCategories = await fetcher(`${environment.CANDAO_API}/api/interests/categories`)
    const interestsMap: Map<string, Array<Interest>> = new Map<string, Array<Interest>>()

    interestsCategories.categories.forEach((category: any) => {
      interestsMap.set(category.name, [])
    })

    interests.forEach((interest: Interest) => {
      const i = interest
      i.icon = interest.icon_colored
      interestsMap.set(interest.category.name, [...(interestsMap.get(interest.category.name) as Array<Interest>), i])
    })

    const categories: Array<Category> = []

    for (let [key, value] of interestsMap) {
      categories.push({
        name: key,
        items: value,
        id: -1,
      })
    }
    return categories
  } catch (e) {
    console.info(e)
    return []
  }
}
