import {
  Flex,
  Text,
  Box,
  Image,
  Button,
  Checkbox,
  Input,
  Link,
  useToast,
  Tooltip,
  Select as ChakraSelect,
  useDisclosure,
  useColorModeValue,
} from '@chakra-ui/react'
import { useState, useEffect, useMemo, useRef } from 'react'
import { readContract, waitForTransaction, getContract } from '@wagmi/core'
import useAsyncEffect from '../../../../hooks/effects/async'
import { loaded } from '../../../../utils/process'
import { useWalletClient } from 'wagmi'
import { useAccountProfileProvider } from '../../../../provider/AccountProfile/context'
import { useContractsProvider } from '../../../../provider/Contracts/contractsProvider'
import { Address } from '../../../../contracts/address'
import { Abi } from '../../../../contracts/abi'
import { trimAddress } from '../../../../utils/parser'
import { fetchApi } from '../../../../utils/fetcher'
import { useEthereumProvider } from '../../../../provider/Ethereum/ethereumProvider'
import { InviteAsModal } from '../../../pages/Home/MiddlePanel/roles/tabs/discussions/Post/Modals/InviteAsModal'
import { _logV } from '../../../../logger'

type ESupportedToken = 'BUSD' | 'USDT' | 'USDC'

export const Offer = () => {
  const toast = useToast()

  const [inputValue, setInputValue] = useState(0)
  const [receiveAmount, setReceiveAmount] = useState(0)
  const [isAccepted, setIsAccepted] = useState(false)

  const [isBalanceLoading, setIsBalanceLoading] = useState(true)
  const [isButtonLoading, setIsButtonLoading] = useState(false)
  const [userCDOBalance, setUserCDOBalance] = useState('')
  const [userFromTokenBalance, setUserFromTokenBalance] = useState('')

  const [tokensLeft, setTokensLeft] = useState('')
  const [token, setToken] = useState<ESupportedToken>('BUSD')
  const [availableTokens, setAvailableTokens] = useState<string[]>(['BUSD', 'USDT'])
  const tokenImage = useMemo(() => `/assets/elements/${token.toLowerCase()}.svg`, [token])
  const tokenAddress = useMemo(() => Address[token], [token])
  const mainInputReference = useRef<any>(null!)

  const contracts = useContractsProvider()
  const accountProfile = useAccountProfileProvider()
  const wallet = useEthereumProvider()

  const walletClient_ = useWalletClient()
  const walletClient = walletClient_.data!

  const inviteAsModal = useDisclosure()

  const textColor = useColorModeValue('white', 'black')

  useEffect(() => {
    if (inputValue === 0) {
      setReceiveAmount(0)
    }

    if (inputValue > 0) {
      setReceiveAmount(Number((inputValue * 10).toFixed(3)))
    }
  }, [inputValue])

  useAsyncEffect(async () => {
    if (accountProfile.address === undefined) {
      return
    }
    await loaded(
      async () => {
        const cdoBalance = (await readContract({
          abi: Abi.ERC20,
          address: Address.W_CDO as address,
          functionName: 'balanceOf',
          args: [accountProfile.address as string],
        })) as string
        setUserCDOBalance(Number(Number(cdoBalance) / 10 ** 18).toString())

        const fromTokenBalanc = (await readContract({
          abi: Abi.ERC20,
          address: tokenAddress,
          functionName: 'balanceOf',
          args: [accountProfile.address as string],
        })) as string

        const decimals = (await readContract({
          abi: Abi.ERC20,
          address: tokenAddress,
          functionName: 'decimals',
          args: [],
        })) as string

        setUserFromTokenBalance(Number(Number(fromTokenBalanc) / 10 ** Number(decimals)).toString())
      },
      setIsBalanceLoading,
      err => console.info(err)
    )
  }, [accountProfile.address, token])

  useAsyncEffect(async () => {
    if (accountProfile.address) {
      const _availableTokens: string[] = []

      for (const token of ['BUSD', 'USDT', 'USDC']) {
        const balance = (await readContract({
          abi: Abi.ERC20,
          address: Address[token],
          functionName: 'balanceOf',
          args: [accountProfile.address as string],
        })) as string
        if (Number(balance) >= 0.1) {
          _availableTokens.push(token)
        }
      }
    }
  }, [accountProfile.address])

  useAsyncEffect(
    async () =>
      await loaded(async () => {
        const tokensLeft = await contracts.wCdo?.read.balanceOf([Address.ICO])
        setTokensLeft(Number(BigInt(tokensLeft.toString()) / BigInt(10 ** 18)).toString())
      }),
    []
  )

  const handlePurchase = async () => {
    if (accountProfile.address === undefined) {
      return
    }

    if (!isAccepted) {
      return
    }

    await loaded(
      async () => {
        const fromTokenContract = getContract({
          abi: Abi.ERC20,
          address: tokenAddress,
          walletClient,
        })

        const allowance = await fromTokenContract.read.allowance([accountProfile.address, Address.ICO])

        if (Number(allowance) < Number(inputValue * 10 ** 18)) {
          try {
            const tx = await fromTokenContract.write?.approve([Address.ICO, BigInt(inputValue) * BigInt(10 ** 18)])

            toast({ title: `Waiting for tx`, status: 'info' })
            await waitForTransaction({ hash: tx })
            toast({ title: `Approved, tx: ${trimAddress(tx)}`, status: 'info' })
          } catch {
            toast({ title: `Unable to get allowance`, status: 'error' })
            return
          }
        }

        try {
          const ICOContract = contracts.ico
          const tx = await ICOContract.write?.claimToken([tokenAddress, Number(inputValue * 10 ** 18)])
          toast({ title: `Waiting for tx`, status: 'info' })
          await waitForTransaction({ hash: tx })
          toast({ title: `Claimed, tx: ${trimAddress(tx)}`, status: 'info' })
          const res = await fetchApi('/ico/refreshHolders', { txHash: tx })
        } catch (e) {
          toast({ title: `Unable to claim`, status: 'error' })
        }
      },
      setIsButtonLoading,
      err => console.info(err)
    )
  }

  return (
    <Flex sx={{ w: 'calc(100% + 32px)', ml: '-16px', mt: '-15px', flexDir: 'column' }}>
      <InviteAsModal
        isOpen={inviteAsModal.isOpen}
        onClose={inviteAsModal.onClose}
        link="https://socialxplorer.io"
      />
      {/* <Flex
        mt="14px"
        alignItems="center"
        justifyContent="space-between"
        fontSize="14px"
      >
        <Text
          color="textQuaternary"
          fontWeight="500"
        >
          Investor
        </Text>
        <Flex
          alignItems="center"
          gap="7px"
        >
          <Text
            fontWeight="500"
            fontSize="14px"
          >
            Chains:{' '}
          </Text>
          <Image
            src="/assets/elements/eth.svg"
            width="19px"
            height="19px"
            borderRadius="50%"
          />
          <Image
            src="/assets/elements/bnb.png"
            width="19px"
            height="19px"
            borderRadius="50%"
          />
        </Flex>
      </Flex> */}
      <Flex
        borderRadius="0 8px 8px 8px"
        border="1px solid"
        borderColor="borderPrimary"
        p="11px"
        alignItems="center"
        justifyContent="space-between"
      >
        <Flex fontSize="12px">
          <Text
            color="textSecondary"
            mr="4px"
          >
            Price:{' '}
          </Text>

          <Text
            color="textPrimary"
            fontWeight="500"
          >
            <Text
              as="span"
              color="#36F"
            >
              $
            </Text>
            0.10 (USD)
          </Text>
        </Flex>
        <Flex fontSize="12px">
          <Text
            color="textSecondary"
            mr="4px"
          >
            Available tokens:{' '}
          </Text>
          <Text
            color="textPrimary"
            fontWeight="500"
          >
            {tokensLeft} CDO
          </Text>
        </Flex>
      </Flex>
      <Flex
        border="1px solid"
        borderColor={inputValue > Number(userFromTokenBalance) && !!wallet.account ? 'redPrimary' : 'borderPrimary'}
        bg="backgroundMain"
        borderRadius="8px"
        justifyContent="space-between"
        width="full"
        py="14px"
        px="22px"
        mt="4px"
        gap="0px"
        onClick={() => mainInputReference.current.focus()}
      >
        <Flex
          direction="column"
          alignItems="start"
          justifyContent="flex-start"
        >
          <Text
            color="textSecondary"
            fontSize="12px"
          >
            You pay:
          </Text>
          <Input
            color="backgroundAccent"
            fontSize="20px"
            value={inputValue}
            onChange={e => setInputValue(Number(e.target.value))}
            fontWeight="600"
            sx={{ border: 'none !important', outline: 'none !important', display: 'flex' }}
            type="number"
            outline="none"
            variant="unstyled"
            ref={mainInputReference}
          />
          <Text
            color="textSecondary"
            fontSize="12px"
            mt="4px"
          >
            ${inputValue}
          </Text>
        </Flex>
        <Flex
          direction="column"
          alignItems="end"
          justify="justify-between"
          flexShrink={0}
        >
          <Text
            color="textQuaternary"
            fontSize="12px"
            mb="12px"
          >
            Balance: {Number(userFromTokenBalance).toFixed(2)}{' '}
            <Text
              as="span"
              color="#36F"
              cursor="pointer"
              onClick={(e: any) => {
                setInputValue(Number(userFromTokenBalance))

                e.stopPropagation()
              }}
            >
              Max
            </Text>
          </Text>
          <Flex
            border="1px solid"
            borderColor="borderPrimary"
            borderRadius="30px"
            px="16px"
            h="min"
            py="4px"
            alignItems="center"
            justifyContent="space-between"
            gap="10px"
            sx={{ position: 'relative' }}
          >
            <Image
              src={tokenImage}
              width="19px"
              height="19px"
            />
            <Text
              color="textQuaternary"
              fontWeight="500"
              fontSize="14px"
            >
              {token}
            </Text>
            <Image
              src="/assets/icons/chevron-down-small.svg"
              onClick={() => null}
              width="16px"
              height="20px"
            />
            <Box
              sx={{
                w: '100%',
                h: 'calc(100% + 2px)',
                position: 'absolute',
                zIndex: '1',
                borderRadius: '30px',
                ml: '-16px',
              }}
              onClick={(e: any) => {
                e.stopPropagation()
              }}
            >
              <ChakraSelect
                fontSize="14px"
                _focus={{ outline: 'none' }}
                sx={{
                  outline: 'none !important',
                  padding: '0',
                  '+.chakra-select__icon-wrapper': {
                    display: 'none',
                  },
                  '&:first-of-type': {
                    fontSize: 0,
                  },
                  h: '31px',
                  transform: 'translateY(-2.5px)',
                  border: 'none',
                  borderRadius: '30px',
                  cursor: 'pointer',
                }}
                onChange={e => setToken(e.target.value as ESupportedToken)}
              >
                {/* <option value="BUSD">BUSD</option>
                <option value="USDT">USDT</option>
                <option value="USDC">USDC</option> */}
                {availableTokens.map((token, idx) => (
                  <option
                    key={`${token}-${idx}`}
                    value={token}
                  >
                    {token}
                  </option>
                ))}
              </ChakraSelect>
            </Box>
          </Flex>
        </Flex>
      </Flex>
      <Flex
        border="1px solid"
        borderColor="borderPrimary"
        bg="backgroundMain"
        borderRadius="8px"
        justifyContent="space-between"
        width="full"
        py="14px"
        px="22px"
        mt="4px"
        gap="0px"
      >
        <Flex
          direction="column"
          alignItems="start"
          justifyContent="flex-start"
        >
          <Text
            color="textSecondary"
            fontSize="12px"
          >
            You receive:
          </Text>
          <Text
            color="backgroundAccent"
            fontSize="20px"
            fontWeight="600"
          >
            {receiveAmount}
          </Text>
          <Text
            color="textSecondary"
            fontSize="12px"
            mt="4px"
          >
            ${inputValue}
          </Text>
        </Flex>
        <Flex
          direction="column"
          alignItems="end"
          flexShrink={0}
        >
          <Text
            mb="12px"
            color="textQuaternary"
            fontSize="12px"
          >
            Balance: {userCDOBalance}
          </Text>
          <Flex
            border="1px solid"
            borderColor="borderPrimary"
            borderRadius="30px"
            px="16px"
            h="min"
            py="4px"
            alignItems="center"
            justifyContent="space-between"
            gap="10px"
          >
            <Image
              src="/assets/elements/wcdo.png"
              width="19px"
              height="19px"
            />
            <Text
              color="textQuaternary"
              fontWeight="500"
              fontSize="14px"
            >
              wCDO
            </Text>
          </Flex>
        </Flex>
      </Flex>
      <Flex
        mt="12px"
        gap="6px"
        onClick={() => setIsAccepted(prev => !prev)}
        cursor="pointer"
      >
        <Checkbox
          iconColor="textQuaternary"
          isChecked={isAccepted}
          onChange={() => setIsAccepted(prev => !prev)}
        />
        <Flex
          as="span"
          display="inline-block"
          fontSize="12px"
        >
          <Text
            as="span"
            color="#8F95B2"
          >
            Select this if you have read and accept&nbsp;
          </Text>
          <Link
            href="https://cdn.hashup.it/legal/SocialXplorer+Newsletter+Terms+2023+09+01.docx"
            color="textBlue"
            textDecoration="underline"
          >
            the terms
          </Link>
          .
        </Flex>
      </Flex>
      <Flex sx={{ alignItems: 'center', gap: '12px' }}>
        {inputValue > Number(userFromTokenBalance) && !!wallet.account ? (
          <Flex
            sx={{
              w: '100%',
              bg: '#F9DADA',
              color: '#D14343',
              borderRadius: '8px',
              alignItems: 'center',
              justifyContent: 'center',
              p: '12px',
              mt: '12px',
              fontSize: '14px',
              fontWeight: '500',
            }}
          >
            Insufficient balance
          </Flex>
        ) : (
          <Tooltip
            hasArrow
            label={!wallet.account ? 'Connect your wallet first' : ''}
          >
            <Button
              onClick={handlePurchase}
              mt="12px"
              borderRadius="8px"
              px="16px"
              py="24px"
              display="flex"
              flexDirection="column"
              placeItems="center"
              variant="dark"
              bg="textBlue"
              cursor="pointer"
              width="100%"
              isLoading={isButtonLoading}
              isDisabled={!isAccepted || !wallet.account}
            >
              {!wallet.account ? (
                <Text
                  color="#FAFBFF"
                  fontSize="14px"
                  fontWeight="500"
                >
                  Connect wallet
                </Text>
              ) : (
                <Flex sx={{ flexDirection: 'column', gap: '2px' }}>
                  <Text
                    color="#FAFBFF"
                    fontSize="14px"
                    fontWeight="500"
                  >
                    Swap
                  </Text>
                </Flex>
              )}
            </Button>
          </Tooltip>
        )}
        <Tooltip
          hasArrow
          label={!wallet.account ? 'Connect your wallet first' : ''}
        >
          <Button
            onClick={inviteAsModal.onOpen}
            mt="12px"
            borderRadius="8px"
            px="16px"
            py="24px"
            display="flex"
            flexDirection="column"
            placeItems="center"
            variant="dark"
            cursor="pointer"
            width="100%"
            isLoading={isButtonLoading}
            isDisabled={!wallet.account}
          >
            <Flex sx={{ flexDirection: 'column', gap: '2px' }}>
              <Text
                color={textColor}
                fontSize="14px"
                fontWeight="500"
              >
                Invite as
              </Text>
            </Flex>
          </Button>
        </Tooltip>
      </Flex>
    </Flex>
  )
}
