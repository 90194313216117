import { Button, Flex, Image, Input, InputGroup, InputRightElement, Text, useToast } from '@chakra-ui/react'
import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'
import { IInvitator, Stage } from '../Onboarding'
import { fetchApi } from '../../../../utils/fetcher'
import { useEthereumProvider } from '../../../../provider/Ethereum/ethereumProvider'
import { loaded } from '../../../../utils/process'
import { getAuthHeaders } from '../../../../utils/auth'
import { useNavigate } from 'react-router-dom'

interface ICreateProfileProps {
  setStage: Dispatch<SetStateAction<Stage>>
  setInvitator: Dispatch<SetStateAction<IInvitator>>
  accessCode: string
  setAccessCode: Dispatch<SetStateAction<string>>
  checkAccessCode: (code: string) => Promise<any>
}

export const InvitationCode: FC<ICreateProfileProps> = ({
  setStage,
  setInvitator,
  accessCode,
  setAccessCode,
  checkAccessCode,
}) => {
  const [isCodeCorrect, setIsCodeCorrect] = useState<boolean | undefined>(undefined)
  const [isContinueButtonDisabled, setIsContinueButtonDisabled] = useState(true)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const { account } = useEthereumProvider()

  const navigate = useNavigate()
  const toast = useToast()

  const setUserAccessCode = async (account: address, accessCode: string) => {
    const res = await loaded(async () => await fetchApi('/profile/access_code', { accessCode: accessCode }))
  }

  const handleCodeInput = (e: any) => {
    setIsCodeCorrect(undefined)
    setIsContinueButtonDisabled(e.target.value === '')
    setAccessCode(e.target.value)
  }

  const handleContinue = () => {
    setIsSubmitting(true)
    checkAccessCode(accessCode).then(res => {
      if (res === 'error') {
        setIsCodeCorrect(false)
      } else {
        setIsCodeCorrect(true)
        // https://api.dicebear.com/6.x/adventurer-neutral/svg?seed=ESSA
        setInvitator({
          name: res.user.name,
          avatar: res.user.profile.avatar,
        })
        // setInvitator({ name: res.user.name, avatar: res.user.profile.avatar });
        setUserAccessCode(account as address, accessCode)
        setStage(Stage.INVITATION_SUCCESS)
      }
      setIsSubmitting(false)
    })
  }

  /** Auto-fill referral code */
  useEffect(() => {
    if (!localStorage.getItem('referral')) {
      return
    }

    setAccessCode(localStorage.getItem('referral') as string)
    setIsContinueButtonDisabled(false)

    toast({ title: 'Auto-filled your referral code', status: 'info' })
  }, [])

  return (
    <Flex
      flexDir="column"
      gap="32px"
      align="start"
      w="100%"
    >
      <Flex
        bgColor="#F3F6FF"
        borderRadius="16px"
        align="center"
        justify="center"
        p="16px"
      >
        <Image src="/assets/icons/envelope-gradient.svg" />
      </Flex>
      <Flex flexDir="column">
        <Text
          color="backgroundAccent"
          fontSize="20px"
          fontWeight="600"
        >
          Invitation code.
        </Text>
        <Text
          color="textQuaternary"
          fontSize="14px"
        >
          This app requires an invitation, Please enter your access code below.
        </Text>
      </Flex>
      <Flex
        flexDir="column"
        gap="8px"
        w="100%"
      >
        <Text
          color="backgroundAccent"
          fontSize="14px"
          fontWeight="500"
        >
          Your access code
        </Text>
        <InputGroup>
          <Input
            placeholder="Enter the code"
            color="textQuaternary"
            onChange={handleCodeInput}
            borderColor="#D8DAE5"
            _hover={{ borderColor: '#D8DAE5' }}
            value={accessCode}
          />
          {isCodeCorrect && (
            <InputRightElement>
              <Image src="/assets/icons/check.svg" />
            </InputRightElement>
          )}
        </InputGroup>
        <Text
          fontSize="14px"
          color="#D14343"
          opacity={isCodeCorrect === false ? '1' : '0'}
        >
          Incorrect code, check again please.
        </Text>
      </Flex>
      <Flex
        w="100%"
        align="center"
        justify="flex-end"
        gap="8px"
      >
        <Button
          variant="light"
          borderColor="#D8DAE5"
          fontSize="12px"
          onClick={() => {
            setIsCodeCorrect(false)
            setIsContinueButtonDisabled(true)
            navigate('/')
          }}
        >
          Close
        </Button>
        <Button
          variant="dark"
          bgColor="textBlue"
          isDisabled={isContinueButtonDisabled}
          onClick={handleContinue}
          isLoading={isSubmitting}
          fontSize="12px"
          color="backgroundMain"
        >
          Continue
        </Button>
      </Flex>
    </Flex>
  )
}
