import React, { useEffect, useState } from 'react'
import useAsyncEffect from '../../hooks/effects/async'
import { fetcher } from '../../utils/fetcher'
import { loaded } from '../../utils/process'
import { environment } from '../../utils/environment'

export interface Icons {
  interests: Map<string, string>
  roles: Map<string, string>
}

export const emptyIcons: Icons = {
  interests: new Map<string, string>(),
  roles: new Map<string, string>(),
}

export const IconsProvider = ({ children }: { children: React.ReactNode }) => {
  const [interests, setInterests] = useState<Map<string, string>>(new Map<string, string>())
  const [roles, setRoles] = useState<Map<string, string>>(new Map<string, string>())

  useAsyncEffect(async () => {
    await loaded(async () => {
      const roles = await fetcher(`${environment.CANDAO_API}/api/profession-role`, {
        pagination: {
          page: 1,
          per_page: 999,
        },
      })
      const interests = await fetcher(`${environment.CANDAO_API}/api/interests/list`, {
        pagination: {
          page: 1,
          per_page: 999,
        },
      })
      const rolesMap = new Map<string, string>()
      const interestsMap = new Map<string, string>()

      roles.profession_roles.forEach((role: any) => {
        rolesMap.set(role.name, role.icon?.replace('https://soc-tkns-prd', 'https://soc-tkns-dev'))
      })

      interests.interests.forEach((interest: any) => {
        interestsMap.set(interest.name, interest.icon_colored?.replace('https://soc-tkns-prd', 'https://soc-tkns-dev'))
      })

      setRoles(rolesMap)
      setInterests(interestsMap)
    })
  }, [])

  return <IconContext.Provider value={{ interests, roles }}>{children}</IconContext.Provider>
}

const IconContext = React.createContext(emptyIcons)

export const useIconProvider = () => {
  const context = React.useContext(IconContext)

  if (!context) {
    throw new Error('`useIconProvider` cannot be used outside of a `IconProvider`!')
  }
  return context
}
