import {
  Button,
  Flex,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  Textarea,
  useDisclosure,
} from '@chakra-ui/react'
import React, { FC, SetStateAction, useCallback, useRef, useState } from 'react'
import { UserAvatar } from '../../../../shared/avatars/UserAvatar'
import { useDropzone } from 'react-dropzone'
import { getAuthHeaders } from '../../../../../utils/auth'
import { fetchApi } from '../../../../../utils/fetcher'
import { loaded } from '../../../../../utils/process'
import 'react-image-crop/dist/ReactCrop.css'
import Cropper from 'react-easy-crop'
import getCroppedImg from '../../../../../utils/getCroppedImg'
import { buildIpfsGateway } from '../../../../../utils/parser'
import { useAccountProfileProvider } from '../../../../../provider/AccountProfile/context'

interface IGeneralTabProps {
  setName: React.Dispatch<SetStateAction<string>>
  setUsername: React.Dispatch<SetStateAction<string>>
  setDescription: React.Dispatch<SetStateAction<string>>
  setLocation: React.Dispatch<SetStateAction<string>>
  setBackground: React.Dispatch<SetStateAction<string>>
  setAvatar: React.Dispatch<SetStateAction<string>>
}

export const GeneralTab: FC<IGeneralTabProps> = ({
  setName,
  setUsername,
  setDescription,
  setLocation,
  setBackground,
  setAvatar,
}) => {
  const data = useAccountProfileProvider()

  const [tmpAvatar, setTmpAvatar] = useState<string | ArrayBuffer | null | any>(data.avatar)
  const [tmpBackground, setTmpBackground] = useState<string | ArrayBuffer | null | any>(data.background)

  const [isAvatarLoading, setIsAvatarLoading] = useState<boolean>(false)
  const [isBackgroundLoading, setIsBackgroundLoading] = useState<boolean>(false)

  const dropzoneAvatarRef = useRef(null!)
  const dropzoneBackgroundRef = useRef(null!)

  const { isOpen, onOpen, onClose } = useDisclosure()
  const { isOpen: isOpenBg, onOpen: onOpenBg, onClose: onCloseBg } = useDisclosure()

  const avatarDropzone = useDropzone({
    onDrop: acceptedFiles => {
      const reader = new FileReader()
      reader.onload = async () => {
        setIsAvatarLoading(true)
        const binary = reader.result
        onOpen()

        setTmpAvatar(binary)
        setIsAvatarLoading(false)
      }
      reader.readAsDataURL(acceptedFiles[0])
    },
  })

  const backgroundDropzone = useDropzone({
    onDrop: acceptedFiles => {
      const reader = new FileReader()
      reader.onload = async () => {
        setIsBackgroundLoading(true)
        const binary = reader.result
        onOpenBg()

        setTmpBackground(binary)
        setIsBackgroundLoading(false)
      }
      reader.readAsDataURL(acceptedFiles[0])
    },
  })

  const handlePicker = (zone: any) => {
    if (zone.current) {
      // @ts-ignore
      zone.current.click()
    }
  }

  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [cropBg, setCropBg] = useState({ x: 0, y: 0 })
  const [rotation, setRotation] = useState(0)
  const [rotationBg, setRotationBg] = useState(0)
  const [zoom, setZoom] = useState(1)
  const [zoomBg, setZoomBg] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
  const [croppedAreaPixelsBg, setCroppedAreaPixelsBg] = useState(null)
  const [_croppedAvatar, setCroppedAvatar] = useState<any>(null)
  const [_croppedBackground, setCroppedBackground] = useState(null)

  const onCropComplete = useCallback((croppedArea: any, croppedAreaPixels: any) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])

  const onCropCompleteBg = useCallback((croppedArea: any, croppedAreaPixels: any) => {
    setCroppedAreaPixelsBg(croppedAreaPixels)
  }, [])

  const showCroppedAvatar = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(tmpAvatar, croppedAreaPixels, rotation)
      setTmpAvatar(await croppedImage?.blob)

      return croppedImage?.base64
    } catch (e) {
      console.error(e)
    }
  }, [croppedAreaPixels, rotation])

  const showCroppedBackground = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(tmpBackground, croppedAreaPixelsBg, rotationBg)
      setTmpBackground(await croppedImage?.blob)

      return croppedImage?.base64
    } catch (e) {
      console.error(e)
    }
  }, [croppedAreaPixelsBg, rotationBg])

  async function close() {
    onClose()

    const croppedAvatar = await showCroppedAvatar()
    setCrop({
      x: 0,
      y: 0,
    })
    setZoom(1)

    const b64 = await fetch(croppedAvatar as any).then(res => res.blob())

    const file = new File([b64], 'avatar.png')
    const formData = new FormData()
    formData.append('file', file)

    const result = await loaded(
      async () =>
        (
          await fetchApi('/post/uploadAsset', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
        ).path
    )

    setAvatar(result)
  }

  async function closeBg() {
    onCloseBg()

    const croppedBg = await showCroppedBackground()
    setCropBg({
      x: 0,
      y: 0,
    })
    setZoomBg(1)

    const b64 = await fetch(croppedBg as any).then(res => res.blob())

    const file = new File([b64], 'background.png')
    const formData = new FormData()
    formData.append('file', file)

    const result = await loaded(
      async () =>
        (
          await fetchApi('/post/uploadAsset', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
        ).path
    )

    setBackground(result)
  }

  return (
    <Flex flexDir="column">
      <Modal
        isOpen={isOpen}
        onClose={close}
        size="full"
      >
        <ModalOverlay />
        <ModalContent
          mx="10px"
          marginBottom={{ base: '10px', md: '64px' }}
        >
          <ModalHeader>Crop your avatar</ModalHeader>
          <ModalCloseButton />
          <ModalBody
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDir="column"
          >
            <Flex
              maxHeight="600px"
              position="relative"
              height="600px"
              width="100%"
            >
              <Cropper
                image={tmpAvatar}
                crop={crop}
                zoom={zoom}
                aspect={4 / 4}
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
              />
            </Flex>
            <Button
              onClick={close}
              width="100%"
              mt="1rem"
            >
              Crop image
            </Button>
          </ModalBody>
        </ModalContent>
      </Modal>

      <Modal
        isOpen={isOpenBg}
        onClose={closeBg}
        size="full"
      >
        <ModalOverlay />
        <ModalContent
          mx="10px"
          marginBottom={{ base: '10px', md: '64px' }}
        >
          <ModalHeader>Crop your background image</ModalHeader>
          <ModalCloseButton />
          <ModalBody
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDir="column"
          >
            <Flex
              maxHeight="600px"
              position="relative"
              height="600px"
              width="100%"
            >
              <Cropper
                image={tmpBackground}
                crop={cropBg}
                zoom={zoomBg}
                aspect={16 / 3}
                onCropChange={setCropBg}
                onCropComplete={onCropCompleteBg}
                onZoomChange={setZoomBg}
              />
            </Flex>
            <Button
              onClick={closeBg}
              width="100%"
              mt="1rem"
            >
              Crop image
            </Button>
          </ModalBody>
        </ModalContent>
      </Modal>

      <Flex
        {...backgroundDropzone.getRootProps({ onClick: event => event.stopPropagation() })}
        bg={tmpBackground !== '' ? 'transparent' : 'bluePlaceholder'}
        backgroundImage={tmpBackground !== '' ? `url(${buildIpfsGateway(tmpBackground)})` : 'none'}
        // backgroundImage={croppedBackground ? `url(${croppedBackground})` : 'none'}
        backgroundPosition="center"
        backgroundRepeat="no-repeat"
        bgSize="cover"
        h="200px"
        w="100%"
        align="center"
        justify="center"
        position="relative"
        gap="8px"
      >
        <input
          {
            /* @ts-ignore */
            ...backgroundDropzone.getInputProps({ ref: dropzoneBackgroundRef })
          }
          onClick={event => event.stopPropagation()}
        />

        {!isBackgroundLoading ? (
          <>
            <Button
              variant="slave"
              borderRadius="50%"
              bgColor="rgba(0, 0, 0, 0.4)"
              h="32px"
              w="32px"
              justifyContent="center"
              alignContent="center"
              onClick={() => handlePicker(dropzoneBackgroundRef)}
            >
              <Image src="/assets/icons/camera-plus.svg" />
            </Button>
            <Button
              variant="slave"
              borderRadius="50%"
              bgColor="rgba(0, 0, 0, 0.4)"
              h="32px"
              w="32px"
              justifyContent="center"
              alignContent="center"
              onClick={() => {
                setBackground('')
                setCroppedBackground(null)
                setTmpBackground('')
              }}
            >
              <Image src="/assets/icons/close.svg" />
            </Button>
          </>
        ) : (
          <Spinner size="lg" />
        )}
        <Flex
          {...avatarDropzone.getRootProps({ onClick: event => event.stopPropagation() })}
          position="absolute"
          transform="translate(0, 50%)"
          bottom="0"
          left="23px"
        >
          <UserAvatar
            size="96px"
            bgColor="bluePlaceholder"
            image={tmpAvatar !== '' ? tmpAvatar : undefined}
          >
            {!isAvatarLoading || tmpAvatar === '' ? (
              <Button
                variant="slave"
                borderRadius="50%"
                bgColor="rgba(0, 0, 0, 0.4)"
                h="32px"
                w="32px"
                justifyContent="center"
                alignContent="center"
                onClick={() => handlePicker(dropzoneAvatarRef)}
              >
                <Image src="/assets/icons/camera-plus.svg" />
              </Button>
            ) : (
              <Spinner size="lg" />
            )}

            <input
              {
                /* @ts-ignore */
                ...avatarDropzone.getInputProps({ ref: dropzoneAvatarRef })
              }
              onClick={event => event.stopPropagation()}
            />
          </UserAvatar>
        </Flex>
      </Flex>
      <Flex
        p="32px 24px"
        mt="32px"
        flexDir="column"
        gap="24px"
      >
        <Flex
          flexDir="column"
          gap="8px"
          w="100%"
        >
          <Text
            fontWeight="500"
            color="backgroundAccent"
            fontSize="14px"
          >
            Name
          </Text>
          <Input
            color="textQuaternary"
            borderColor="#D8DAE5"
            _hover={{ borderColor: '#D8DAE5' }}
            onChange={(e: any) => setName(e.target.value)}
            defaultValue={data.name}
          />
        </Flex>
        <Flex
          flexDir="column"
          gap="8px"
          w="100%"
        >
          <Text
            fontWeight="500"
            color="backgroundAccent"
            fontSize="14px"
          >
            Username
          </Text>
          <Input
            color="textQuaternary"
            borderColor="#D8DAE5"
            _hover={{ borderColor: '#D8DAE5' }}
            onChange={(e: any) => setUsername(e.target.value)}
            defaultValue={data.nickname}
          />
        </Flex>
        <Flex
          flexDir="column"
          gap="8px"
          w="100%"
        >
          <Text
            fontWeight="500"
            color="backgroundAccent"
            fontSize="14px"
          >
            Description
          </Text>
          <Text
            color="textSecondary"
            fontSize="14px"
          >
            Your extended description
          </Text>
          <Textarea
            color="textQuaternary"
            resize="none"
            borderColor="#D8DAE5"
            _hover={{ borderColor: '#D8DAE5' }}
            onChange={(e: any) => setDescription(e.target.value)}
            defaultValue={data.description}
          />
        </Flex>
        <Flex
          flexDir="column"
          gap="8px"
          w="100%"
        >
          <Text
            fontWeight="500"
            color="backgroundAccent"
            fontSize="14px"
          >
            Location
          </Text>
          <InputGroup>
            <Input
              color="textQuaternary"
              borderColor="#D8DAE5"
              _hover={{ borderColor: '#D8DAE5' }}
              onChange={(e: any) => setLocation(e.target.value)}
              defaultValue={data.location}
            />
            <InputRightElement
              bg="none"
              children={<Image src="/assets/icons/location.svg" />}
            />
          </InputGroup>
          <Text
            color="textSecondary"
            fontSize="14px"
            mt="16px"
          >
            This can only be changed a few times. Make sure you enter the information of the person using the account.
          </Text>
        </Flex>
      </Flex>
    </Flex>
  )
}
