import React, { useState } from 'react'
import { Box, ButtonProps, Center, Flex, Image, Spinner, Text } from '@chakra-ui/react'
import SpinnerText from '../../../../../shared/tags/SpinnerText'
import useAsyncEffect from '../../../../../../hooks/effects/async'
import { loaded } from '../../../../../../utils/process'
import { fetchApi } from '../../../../../../utils/fetcher'
import { useProfileProvider } from '../../../../../../provider/Profile/profileProvider'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { trimAddress } from '../../../../../../utils/parser'
import {
  Pagination,
  usePagination,
  PaginationNext,
  PaginationPage,
  PaginationPrevious,
  PaginationContainer,
  PaginationPageGroup,
  PaginationSeparator,
} from '@ajna/pagination'
import { UserWallLayout } from '../UserWallLayout'
import { UserWallPagination } from '../UserWallPagination'
export const UserTransactions = () => {
  const [txs, setTxs] = useState<any[]>()
  const [isLoading, setIsLoading] = useState(true)
  const outerLimit = 2
  const innerLimit = 2
  const [numOfPages, setNumOfPages] = useState(1)
  const { pages, pagesCount, offset, currentPage, setCurrentPage, setIsDisabled, isDisabled, pageSize, setPageSize } =
    usePagination({
      total: numOfPages * 10,
      limits: {
        outer: outerLimit,
        inner: innerLimit,
      },
      initialState: {
        pageSize: 10,
        isDisabled: false,
        currentPage: 1,
      },
    })

  const profile = useProfileProvider()

  useAsyncEffect(async () => {
    if (!profile.address) {
      setIsLoading(false)
      return
    }

    void loaded(async () => {
      try {
        const data = await fetchApi(`/tx/page/1`, { user: profile.address?.toLowerCase() })
        setTxs(data.transactions)
        setNumOfPages(data.totalPages)
      } catch {
        setTxs([])
      }
    }, setIsLoading)
  }, [profile.address])

  const baseStyles: ButtonProps = {
    w: '32px',
    h: '32px',
    fontSize: 'sm',
    border: 'none',
  }

  const handlePageChange = async (page: number) =>
    loaded(async () => {
      setCurrentPage(page)
      const data = await fetchApi(`/tx/page/${page}`, { user: profile.address?.toLowerCase() })

      setTxs(data.transactions)
      setNumOfPages(data.totalPages)
    })

  return (
    <UserWallLayout
      heading="Transactions"
      headingDetails={`${isLoading ? <SpinnerText /> : txs?.length || 0} transactions`}
    >
      {isLoading ? (
        <Center>
          <Spinner />
        </Center>
      ) : (
        txs?.map(tx => (
          <Flex
            key={tx.hash}
            p="10px 20px"
            gridGap={{ base: '12px', md: '0px' }}
            alignItems={{ base: 'flex-start', md: 'center' }}
            direction={{ base: 'column', md: 'row' }}
            justifyContent="space-between"
          >
            <Link to={`/tx/${tx.hash}`}>
              <Flex direction="column">
                <Text color="#3366FF">{tx.hash.slice(0, 10)}···</Text>
                <Text color="textSecondary">{moment(tx._created_at).fromNow()}</Text>
              </Flex>
            </Link>
            <Flex alignItems="center">
              <Flex
                gridGap="8px"
                alignItems={{ base: 'flex-end', lg: 'center' }}
                direction={{ base: 'column', lg: 'row' }}
              >
                <Flex
                  gridGap="8px"
                  alignItems="center"
                >
                  <Text color="textSecondary">From</Text>
                  <Link to={`/profile/${tx.sender}`}>
                    <Box
                      gridGap="8px"
                      borderRadius="8px"
                      display="flex"
                      p="6px 8px"
                      border="1px solid"
                      borderColor="borderPrimary"
                    >
                      <Text color="#3366FF">{trimAddress(tx.sender)}</Text>
                      <Box
                        display="flex"
                        borderRadius="4px"
                        bgColor="borderPrimary"
                        alignItems="center"
                        justifyContent="center"
                        w="20px"
                        h="20px"
                      >
                        <Image
                          src="/assets/icons/link-icon.svg"
                          boxSize="14px"
                        />
                      </Box>
                    </Box>
                  </Link>
                </Flex>
                <Flex
                  gridGap="8px"
                  alignItems="center"
                >
                  <Text color="textSecondary">To</Text>
                  <Link to={`/profile/${tx.target}`}>
                    <Box
                      gridGap="8px"
                      borderRadius="8px"
                      display="flex"
                      p="6px 8px"
                      border="1px solid"
                      borderColor="borderPrimary"
                    >
                      <Text color="#3366FF">{trimAddress(tx.target)}</Text>
                      <Box
                        display="flex"
                        borderRadius="4px"
                        bgColor="borderPrimary"
                        alignItems="center"
                        justifyContent="center"
                        w="20px"
                        h="20px"
                      >
                        <Image
                          src="/assets/icons/link-icon.svg"
                          boxSize="14px"
                        />
                      </Box>
                    </Box>
                  </Link>
                </Flex>
              </Flex>
            </Flex>
            <Link to={`/tx/${tx.hash}`}>
              <Flex
                w="32px"
                display={{ base: 'none', md: 'flex' }}
                h="32px"
                border="1px solid"
                borderColor="borderPrimary"
                borderRadius="4px"
                alignItems="center"
                justifyContent="center"
              >
                <Image
                  src="/assets/icons/eye-icon2.svg"
                  boxSize="16px"
                />
              </Flex>
            </Link>
          </Flex>
        ))
      )}
      <UserWallPagination
        totalPages={numOfPages}
        handlePageChange={handlePageChange}
        currentPage={currentPage}
      />
    </UserWallLayout>
  )
}

export default UserTransactions
