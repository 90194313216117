import { Box, Button, Flex, Grid, Image, Text, Tooltip, useDisclosure, useToast } from '@chakra-ui/react'
import { useEffect, useMemo, useState } from 'react'
import { UserAvatar } from '../../../shared/avatars/UserAvatar'
import { UserEditModal } from '../UserEditModal/UserEditModal'
import { useProfileProvider } from '../../../../provider/Profile/profileProvider'
import moment from 'moment/moment'
import { UserCode } from '../UserCode/UserCode'
import SpinnerText from '../../../shared/tags/SpinnerText'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useFriend } from '../../../../hooks/useFriend'
import { useAccountProfileProvider } from '../../../../provider/AccountProfile/context'
import { AddressesPopup } from './AddressesPopup'
import { useEthereumProvider } from '../../../../provider/Ethereum/ethereumProvider'
import { RolesWithIndicator } from '../../../shared/roles/RolesWithIndicator'
import { AddressZero } from '../../../../utils/ethereum'
import { buildIpfsGateway, formatUsd } from '../../../../utils/parser'
import { fetchApi } from '../../../../utils/fetcher'
import useAsyncEffect from '../../../../hooks/effects/async'
import { formatUnits } from 'viem'
import { loaded } from '../../../../utils/process'
import useWindowDimensions from '../../../../hooks/utils/useWindowDimensions'
import RolesWithButtonFollow from './RolesWithButtonFollow'
import { InterestsAndRolesModal } from './InterestAndRolesModal'
import { IToken, PowerDetailsModal } from '../../Home/MiddlePanel/roles/tabs/discussions/Post/Modals/PowerDetailsModal'
import { useIconProvider } from '../../../../provider/Icons/iconsProvider'

enum EAction {
  Friend,
  Follow,
  Message,
  Edit,
}

export const UserHeader = () => {
  const { width } = useWindowDimensions()
  const [roles, setRoles] = useState<string[]>([])
  const [accountPopup, setAccountPopup] = useState(false)
  const [power, setPower] = useState<Pick<IToken, 'value' | 'decimals'> | undefined>()
  const [powerComponents, setPowerComponents] = useState<[]>()

  const { id } = useParams()
  const { state } = useLocation()
  const { edit: shouldOpenEditModal } = (state ?? { edit: '' }) as { edit: boolean }
  const { isOpen, onOpen, onClose } = useDisclosure()

  const qrCodeDisclosure = useDisclosure()
  const interestsAndRolesDisclosure = useDisclosure()
  const powerDetailsDisclosure = useDisclosure()
  const toast = useToast()
  const navigate = useNavigate()

  const data = useProfileProvider()
  const account = useAccountProfileProvider()
  const wallet = useEthereumProvider()

  const friend = useFriend({ address: id as address })
  const isSelf = wallet.account?.toLowerCase() === id?.toLowerCase() && !!wallet.account

  const shouldOnboardingBeShown = wallet.account == id?.toLowerCase() && !false && !data.isLoading && !data.isOnboarded

  const { interests } = useIconProvider()

  const handleQrCode = () => {
    qrCodeDisclosure.onToggle()
  }

  const handleMessage = () => {
    navigate('/chat')
  }

  const subActions: Array<{ id: EAction; icon: string; name: string; action?: () => void }> = [
    {
      id: EAction.Friend,
      action: friend.friend,
      icon: 'userAdd.svg',
      name: friend.isFriended ? 'Unfriend' : 'Friend',
    },
    {
      id: EAction.Message,
      // TODO: Fix code duplication
      action: wallet.account && wallet.isAuthed ? handleMessage : undefined,
      icon: 'envelope.svg',
      name: 'Message',
    },
  ]

  const actionEdit = {
    id: EAction.Edit,
    action: onOpen,
    icon: 'dots.svg',
    name: 'Edit',
  }

  useEffect(() => setRoles(data.roles ?? []), [data.isLoading])

  /** Open edit modal if applicable */
  useEffect(() => {
    if (!shouldOpenEditModal || shouldOnboardingBeShown || data.isLoading) {
      return
    }

    /* Reset state */
    navigate(`/profile/${wallet.account ?? AddressZero}`, { state: {} })
    onOpen()
  }, [shouldOpenEditModal, data.isLoading])

  useAsyncEffect(
    async () =>
      await loaded(async () => {
        setPower(undefined)
        const response = await fetchApi(`/profile/power`, { address: id })
        setPower(response)
        setPowerComponents(response.values)
      }),
    [id, wallet]
  )

  const chainsData = useMemo(() => {
    return {
      chains: [
        {
          name: 'BEP-20',
          tokens: powerComponents?.filter((pc: any) => pc.chain === 'bsc') ?? [],
        },
        {
          name: 'ERC-20',
          tokens: powerComponents?.filter((pc: any) => pc.chain === 'eth') ?? [],
        },
      ],
    }
  }, [powerComponents])

  return (
    <Flex
      w="100%"
      flexDir="column"
      color="textPrimary"
      padding="0"
    >
      <UserEditModal
        isOpen={isOpen}
        onClose={onClose}
      />
      {wallet.account === id && (
        <PowerDetailsModal
          isOpen={powerDetailsDisclosure.isOpen}
          onClose={powerDetailsDisclosure.onClose}
          power={power}
          chains={chainsData.chains}
        />
      )}

      <InterestsAndRolesModal
        isOpen={interestsAndRolesDisclosure.isOpen}
        onClose={interestsAndRolesDisclosure.onClose}
        data={{ interests: data.interests, roles: data.roles }}
      />
      {isSelf && <UserCode disclosure={qrCodeDisclosure} />}
      <Flex
        borderRadius={{ base: '0', md: '16px 16px 0 0' }}
        w="100%"
        h="148px"
        position="relative"
      >
        <Box
          position="absolute"
          top="50%"
          left="50%"
          transform="translate(-50%, -66%)"
          fontSize="72px"
          color="textTertiary"
        >
          <SpinnerText />
        </Box>
        <Flex
          position="relative"
          borderRadius={{ base: '0', md: '16px 16px 0 0' }}
          w="100%"
          h="148px"
          backgroundImage={`url(${buildIpfsGateway(data.background) ?? ''})`}
          backgroundPosition="center center"
          backgroundRepeat="no-repeat"
          backgroundSize="cover"
          borderColor="borderPrimary"
          background={data.isLoading ? 'unset' : !data.background ? 'bluePlaceholder' : ''}
          transition={!data.background ? 'background .66s cubic-bezier(0, 0, 0, 1)' : 'unset'}
        />
      </Flex>
      <Flex
        minH={{ base: 'unset', md: '223px' }}
        bgColor="backgroundMain"
        border="1px solid"
        borderX={{ base: 'none', md: '1px solid' }}
        borderLeftColor="borderPrimary !important"
        borderRightColor="borderPrimary !important"
        borderColor="borderPrimary"
        borderTop="none"
        borderRadius="0 0 16px 16px"
        w="100%"
        p={{ base: '16px', md: '24px' }}
        position="relative"
        justify={{ base: 'flex-start', lg: 'space-between' }}
        direction={{ base: 'column', lg: 'row' }}
      >
        <Flex
          position="absolute"
          transform="translate(0, -50%)"
          top="0"
        >
          <UserAvatar
            size="112px"
            bgColor="bluePlaceholder"
            image={data.avatar}
          />
        </Flex>

        <Flex
          gap="8px"
          pt={{ base: '24px', lg: '0px' }}
          direction="row"
          display={{ md: 'flex', lg: 'none' }}
          position="relative"
          top="-30px"
          width="100%"
          justify="end"
        >
          {(width ?? 0) >= 768 && (width ?? 0) <= 992 && (
            <RolesWithButtonFollow
              wallet={wallet}
              id={id}
              subActions={subActions}
              account={account}
              friend={friend}
            />
          )}
          <Flex
            justify="end"
            gap="8px"
            display={{ base: 'none', md: 'flex' }}
          >
            {id?.toLowerCase() === account.address /* TODO: factor out to userProfileProvider */ && (
              <Tooltip
                key={actionEdit.name}
                label={actionEdit.name}
              >
                <Button
                  width="32px"
                  p="0"
                  onClick={actionEdit.action}
                >
                  <Image
                    src={`/assets/icons/${actionEdit.icon}`}
                    boxSize="16px"
                  />
                </Button>
              </Tooltip>
            )}
            {id?.toLowerCase() === account.address && (
              <Button
                width="32px"
                p="0"
                onClick={handleQrCode}
              >
                <Image
                  src="/assets/icons/qrCode.svg"
                  boxSize="16px"
                />
              </Button>
            )}
            <AddressesPopup />
          </Flex>
        </Flex>

        <Flex
          mt={{ base: '32px', md: '-16px', lg: '32px' }}
          maxWidth={{ base: '100%', lg: '50%' }}
          justify="space-between"
        >
          <Flex
            flexDir="column"
            w="100%"
          >
            <Flex
              align="center"
              gap="6px"
            >
              <Text
                color="backgroundAccent"
                fontWeight="600"
                fontSize="18px"
              >
                {data.isLoading ? <SpinnerText /> : data.name || 'No name'}
              </Text>
            </Flex>
            <Flex
              align="center"
              sx={{ '*': { whiteSpace: 'nowrap' } }}
            >
              {(data.isLoading || data.nickname) && (
                <Text
                  color="textSecondary"
                  mt="4px"
                  fontSize={{ base: '12px' }}
                >
                  @{data.isLoading ? <SpinnerText /> : data.nickname}
                </Text>
              )}
              {(data.isLoading || data.nickname) && (
                <Box
                  h="2px"
                  mt="4px"
                  w="2px"
                  bg="#8F95B2"
                  borderRadius="100%"
                  mx="14px"
                  display={{ base: 'none', md: 'block' }}
                />
              )}

              <Tooltip
                label={wallet.account === id ? 'Click to see more' : 'Only account owner can see detailed balance'}
                hasArrow
              >
                <Flex
                  alignItems="center"
                  justifyContent="space-between"
                  gap="4px"
                  ml={{ base: '6px', md: 'unset' }}
                  onClick={wallet.account === id ? powerDetailsDisclosure.onOpen : () => null}
                  cursor={wallet.account === id ? 'pointer' : 'default'}
                >
                  <Image
                    src="/assets/icons/coin-dollar.svg"
                    height="15px"
                    width="15px"
                    mt="4px"
                  />
                  <Text
                    color="textSecondary"
                    mt="4px"
                    fontSize={{ base: '12px' }}
                  >
                    Power:
                  </Text>
                  <Text
                    color="textBlue"
                    mt="4px"
                    fontSize={{ base: '12px' }}
                  >
                    $
                    {power && power.value ? (
                      formatUsd(formatUnits(BigInt(power.value), power.decimals))
                    ) : (
                      <SpinnerText />
                    )}
                  </Text>
                </Flex>
              </Tooltip>
              <Box
                h="2px"
                mt="4px"
                w="2px"
                bg="#8F95B2"
                borderRadius="100%"
                mx="14px"
                display={{ base: 'none', md: 'block' }}
              />

              <Flex
                gap="16px"
                align="center"
                mt="4px"
                fontSize={{ base: '12px' }}
                display={{ base: 'none', md: 'flex' }}
              >
                {data.isLoading || (data.location && !data.address && !data.isLoading) || (
                  <Flex
                    align="center"
                    gap="4px"
                  >
                    <Image
                      src="/assets/icons/location.svg"
                      height="1.25em"
                    />
                    <Text color="textBlue">{data.isLoading ? <SpinnerText /> : data.location || 'Blockchain'}</Text>
                  </Flex>
                )}
                {(data.isLoading || data.address) && !!data._created_at && (
                  <>
                    <Box
                      h="2px"
                      w="2px"
                      bg="#8F95B2"
                      borderRadius="100%"
                      mx=""
                    />

                    <Flex
                      align="center"
                      gap="4px"
                    >
                      <Image
                        src="/assets/icons/calendar.svg"
                        height="1.25em"
                      />
                      <Text
                        color="textSecondary"
                        fontSize={{ base: '12px' }}
                      >
                        Joined {data.isLoading ? <SpinnerText /> : moment(data._created_at).format('MMM YYYY')}
                      </Text>
                    </Flex>
                  </>
                )}
              </Flex>
            </Flex>

            <Text
              m={{ base: '8px 0', lg: '16px 0' }}
              fontSize="14px"
            >
              {/** todo: link parser / hashtag */}
              {data.description}
            </Text>

            <Grid
              templateColumns="repeat(3, 1fr)"
              gap="10px"
              mt="8px"
              display={{ base: 'grid', md: 'none' }}
            >
              <Box
                p="8px"
                borderRadius="8px"
                border="1px solid"
                borderColor="#EDEFF5"
                bg="backgroundTertiary"
              >
                <Text
                  color="textSecondary"
                  fontSize="12px"
                >
                  Friends
                </Text>
                <Text fontSize="12px">{data.friends?.length ?? 0}</Text>
              </Box>
              <Box
                p="8px"
                borderRadius="8px"
                border="1px solid"
                borderColor="#EDEFF5"
                bg="backgroundTertiary"
              >
                <Text
                  color="textSecondary"
                  fontSize="12px"
                >
                  Followers
                </Text>
                <Text fontSize="12px">{data.followers?.length ?? 0}</Text>
              </Box>
              <Box
                p="8px"
                borderRadius="8px"
                border="1px solid"
                borderColor="#EDEFF5"
                bg="backgroundTertiary"
              >
                <Text
                  color="textSecondary"
                  fontSize="12px"
                >
                  Following
                </Text>
                <Text fontSize="12px">{data.followed?.length ?? 0}</Text>
              </Box>
            </Grid>
            {/* </Flex> */}

            {(width ?? 0) >= 992 && (
              <RolesWithButtonFollow
                wallet={wallet}
                id={id}
                subActions={subActions}
                account={account}
                friend={friend}
              />
            )}
          </Flex>
          <Flex
            justify="end"
            gap="8px"
            display={{ base: 'flex', md: 'none' }}
          >
            {id?.toLowerCase() === account.address && (
              <Button
                width="32px"
                p="0"
                onClick={handleQrCode}
              >
                <Image
                  src="/assets/icons/qrCode.svg"
                  boxSize="16px"
                />
              </Button>
            )}
            {id?.toLowerCase() === account.address /* TODO: factor out to userProfileProvider */ && (
              <Tooltip
                key={actionEdit.name}
                label={actionEdit.name}
              >
                <Button
                  width="32px"
                  p="0"
                  onClick={actionEdit.action}
                  borderColor="blue"
                >
                  <Image
                    src={`/assets/icons/${actionEdit.icon}`}
                    boxSize="16px"
                  />
                </Button>
              </Tooltip>
            )}
          </Flex>
        </Flex>
        <Flex
          flexDir="column"
          justify="space-between"
          align={{ base: 'flex-start', lg: 'flex-end' }}
          width={{ base: '100%', lg: 'auto' }}
          gridGap={{ base: '12px', lg: '0px' }}
        >
          <Flex
            gap="8px"
            justify="end"
            pt={{ base: '24px', lg: '0px' }}
            direction={{ base: 'column', lg: 'row' }}
            position="relative"
            display={{ base: 'flex', md: 'none', lg: 'flex' }}
          >
            <Flex
              gap="8px"
              display={{ base: 'none', md: '', lg: 'flex' }}
            >
              {id?.toLowerCase() === account.address /* TODO: factor out to userProfileProvider */ && (
                <Tooltip
                  key={actionEdit.name}
                  label={actionEdit.name}
                >
                  <Button
                    width="32px"
                    p="0"
                    onClick={actionEdit.action}
                  >
                    <Image
                      src={`/assets/icons/${actionEdit.icon}`}
                      boxSize="16px"
                    />
                  </Button>
                </Tooltip>
              )}
              {id?.toLowerCase() === account.address && (
                <Button
                  width="32px"
                  p="0"
                  onClick={handleQrCode}
                >
                  <Image
                    src="/assets/icons/qrCode.svg"
                    boxSize="16px"
                  />
                </Button>
              )}
              <AddressesPopup />
            </Flex>
          </Flex>
          <Flex
            onClick={() => interestsAndRolesDisclosure.onOpen()}
            mt={{ base: '0', md: '16px', lg: '0' }}
            flexDir={{ base: 'column', md: 'row' }}
            gap="16px"
            align={{ base: 'start', lg: 'end' }}
            mb={{ base: '16px', md: '0' }}
          >
            <Flex
              flexDir="column"
              gap="6px"
              width={{ base: '100%', md: 'auto' }}
              minWidth="auto"
            >
              {!!data.roles?.length && (
                <Text
                  color="textSecondary"
                  fontSize="12px"
                >
                  Roles ({data.roles.length})
                </Text>
              )}
              <Flex
                gap="4px"
                align="center"
                flexWrap={{ base: 'wrap', md: 'nowrap' }}
              >
                <RolesWithIndicator
                  roles={data.roles}
                  compact
                />
              </Flex>
            </Flex>
          </Flex>
        </Flex>

        {(width ?? 0) <= 768 && (
          <RolesWithButtonFollow
            wallet={wallet}
            id={id}
            subActions={subActions}
            account={account}
            friend={friend}
          />
        )}
      </Flex>
    </Flex>
  )
}
