import React, { useState } from 'react'
import { Box, Flex, Text } from '@chakra-ui/react'
import useAsyncEffect from '../../../../../hooks/effects/async'
import { loaded } from '../../../../../utils/process'
import { Link, useLocation } from 'react-router-dom'
import { ButtonFollow } from '../../../../shared/actions/ButtonFollow'
import { fetchApi } from '../../../../../utils/fetcher'
import { IId } from '../../../../../provider/Profile/profileProvider'
import { UserPopup } from '../../../../shared/popups/UserPopup'
import Delayed from '../../../../shared/actions/Delayed'
import { buildIpfsGateway, getInitials } from '../../../../../utils/parser'
import SpinnerText from '../../../../shared/tags/SpinnerText'
import { useEthereumProvider } from '../../../../../provider/Ethereum/ethereumProvider'
import { AddressZero } from '../../../../../utils/ethereum'
import { useAccount } from 'wagmi'
import { getBlockedHeaders } from '../../../../../utils/auth'
import { useAccountProfileProvider } from '../../../../../provider/AccountProfile/context'

export interface ITopPeople {
  name: string
  initials: string
  username: string
  isVerified: boolean
  isFollowing: boolean
  avatar_color: string
  avatar_bgColor: string
}

export const TopPeople = () => {
  const [data, setData] = useState<IId[]>([])
  const [isLoading, setIsLoading] = useState(true)

  const wallet = useEthereumProvider()
  const account = useAccount()
  const accountProfile = useAccountProfileProvider()
  const { pathname } = useLocation()

  useAsyncEffect(async () => {
    if (wallet.isConnecting || account.isConnecting || account.isReconnecting) {
      return
    }

    void loaded(async () => {
      const data: IId[] = await fetchApi(
        '/profile/top',
        { address: wallet.account ?? AddressZero },
        // TODO: Check for correctness
        { headers: getBlockedHeaders(accountProfile.blockedUsers) }
      )

      data.forEach(datum => (datum.address = datum.address_?.[0]))
      setData(data)
    }, setIsLoading)
  }, [wallet.account, wallet.isConnecting, account.isConnecting, account.isReconnecting, pathname])

  const [showUserPopup, setShowUserPopup] = useState<`0x${string}`[] | undefined>(undefined)

  const handleOnHover = (address: `0x${string}`[]) => {
    setTimeout(() => {
      setShowUserPopup(address)
    }, 100)
  }

  const handleOnUnhover = (address: `0x${string}`[]) => {
    setTimeout(() => {
      setShowUserPopup(undefined)
    }, 100)
  }

  return (
    <Flex
      flexDirection="column"
      w="100%"
      bgColor="backgroundMain"
      borderRadius={{ base: '0', md: '16px' }}
      border={{ base: 'none', md: '1px solid' }}
      borderColor="borderPrimary"
      borderY="1px solid"
      borderTopColor="borderPrimary !important"
      borderBottomColor="borderPrimary !important"
      borderX="1px solid"
      borderLeftColor="borderPrimary !important"
      borderRightColor="borderPrimary !important"
      gridGap="16px"
      p="16px"
      h="min"
    >
      <Text
        color="backgroundAccent"
        fontSize="16px"
        fontWeight="600"
      >
        Top people
      </Text>
      {data.slice(0, 5).map((datum, index: number) => {
        return (
          <Flex
            key={`${datum.address}_${index}`}
            w="100%"
            justifyContent="space-between"
            alignItems="center"
          >
            <Link
              to={`/profile/${datum.address}`}
              onMouseEnter={() => handleOnHover(datum.address_)}
              onMouseLeave={() => handleOnUnhover(datum.address_)}
              style={{ display: 'flex', flex: '1' }}
            >
              <Flex
                gridGap="8px"
                position="relative"
              >
                <Box
                  borderRadius="50%"
                  textTransform="uppercase"
                  display="flex"
                  w="40px"
                  h="40px"
                  alignItems="center"
                  justifyContent="center"
                  backgroundImage={buildIpfsGateway(datum.avatar)}
                  backgroundPosition="center center"
                  backgroundRepeat="no-repeat"
                  backgroundSize="cover"
                  color="#2952CC"
                  bgColor="bluePlaceholder"
                  userSelect="none"
                  zIndex={1}
                >
                  {!datum.avatar && getInitials(datum.name || 'No name')}
                </Box>
                <Flex
                  flexDirection="column"
                  justifyContent="center"
                >
                  <Flex
                    alignItems="center"
                    gridGap="5px"
                  >
                    <Text
                      fontSize="14px"
                      color={datum.name ? 'textPrimary' : 'textTertiary'}
                      sx={{ maxWidth: '16ch', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                    >
                      {datum.name || 'No name'}
                    </Text>
                  </Flex>
                  {datum.nickname && (
                    <Text
                      fontSize="12px"
                      color="textSecondary"
                      sx={{ maxWidth: '16ch', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                    >
                      {datum.nickname}
                    </Text>
                  )}
                </Flex>
                {showUserPopup === datum.address_ && (
                  <Delayed>
                    <UserPopup address={datum.address} />
                  </Delayed>
                )}
              </Flex>
            </Link>
            <ButtonFollow target={datum.address} />
          </Flex>
        )
      })}
      {isLoading && (
        <Flex
          justifyContent="center"
          alignItems="center"
          height="40px"
          fontSize="32px"
        >
          <SpinnerText />
        </Flex>
      )}
    </Flex>
  )
}

export default TopPeople
