import { zeroAddress } from 'viem'
import { isProduction } from '../utils/environment'

type EAddress =
  | 'FOLLOW'
  | 'FRIEND'
  | 'ID'
  | 'ROLE'
  | 'INTEREST'
  | 'ON_CHAIN_REF'
  | 'QUEST'
  | 'ICO'
  | 'GROUPS'
  | 'GROUPS_DEPLOYER'
  | 'W_CDO'
  | 'BUSD'
  | 'USDT'
  | 'USDC'
  | 'TRANSFER'
  | 'W_CDO_PROD'
  | 'CANDAO_REFERRAL'

/**
 * BEP20 token addresses
 */
export const Address: { [key in EAddress]: address } = {
  FOLLOW: isProduction() ? '0xa90C1074aC168bE0257FA385CD56034AF987DFD0' : '0x318e9216975539287D6CfF9f223c39A8245006ea',
  FRIEND: isProduction() ? '0xC62BcafAeF0579Ff341d30F143ccc75F951f2304' : '0x536d0329b8Be3D2d538508925c619E163e74B18B',
  ID: isProduction() ? '0x480F94D38314FB7B494203e01FA546A3b2218775' : '0x556f1B590fF7a58ff6e31e7E39864bf88a7d109C',
  ROLE: isProduction() ? '0xf19a86746a34C43071865DEE5D8F0bb074B43933' : '0x620e4d4337fF06A461E667Adc61169Af2eFb69Da',
  INTEREST: isProduction()
    ? '0xCde0988e28856A76d1070fA4dD1776737cb8d867'
    : '0x0ae709A9B3e289571A86E54238f98998dABfFdF5',
  ON_CHAIN_REF: isProduction()
    ? '0xBd0Ab18c65C50a70c216986F121137e00b182879'
    : '0x1c23C43A73d525E833c1BcbC556739eD5335FD29',
  QUEST: isProduction() ? '0x78689c1821E283689D5A1B102F6dA9bd102F082D' : '0x0980fd1863DA8C72C1c6e193793a3a1853d1De08',
  ICO: isProduction() ? '0xbED6141c3f6f651766F3C606E2ae023c1B9BC7a9' : '0x20F32B3F8000acE40b6750E9Ff65ab7f83eb1E2E',
  GROUPS: isProduction() ? '0x350F80EFE8d116BD4013E4af55771c0B36244e3b' : '0x147Ca17E234F409594875EB0555dE3AcA66F81D2',
  GROUPS_DEPLOYER: isProduction()
    ? '0x604749504efAc3a8f6c66d7211d4bdE880861b43'
    : '0x54C65052ec1a1B314c3F07e84dddEC69Fd883c60',
  CANDAO_REFERRAL: isProduction()
    ? '0xA5a6fF17C90B73A4619e6f0745b9767856ab3777'
    : '0x1a5911f19121944A6340F222da9BB3550d74dcb0',

  TRANSFER: isProduction()
    ? '0xB840ABBfc54Dc3018Cf4A3db56D977f5521C949d'
    : '0x99695D3A0557AcbE5539338d7BFb8451001d3cc6',

  /* Candao token */
  W_CDO: isProduction() ? '0x82bE056c76970e36bB7a4CE7dAA2692cB1538959' : '0xb6270bE2Ad9579f98Ca6B90Df580dF19c098B968',
  W_CDO_PROD: '0x82bE056c76970e36bB7a4CE7dAA2692cB1538959',

  /* USD token */
  BUSD: isProduction() ? '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56' : '0xeD24FC36d5Ee211Ea25A80239Fb8C4Cfd80f12Ee',
  USDT: isProduction() ? '0x55d398326f99059fF775485246999027B3197955' : '0x337610d27c682E347C9cD60BD4b3b107C9d34dDd',
  USDC: isProduction() ? '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d' : '0x64544969ed7ebf5f083679233325356ebe738930',
}
