const enum State {
  PRODUCTION = 'production',
  DEVELOPMENT = 'development',
}

const enum Domain {
  REMOTE_PRODUCTION = 'socialxplorer.io',
  REMOTE_DEVELOPMENT = 'dev.socialxplorer.io',
  LOCAL = 'localhost',
}

const isProduction /* mainnet */ = () => process.env.REACT_APP_ENV === State.PRODUCTION
const isDevelopment /* testnet */ = () => process.env.REACT_APP_ENV === State.DEVELOPMENT
const isLocal = () => window.location.hostname === Domain.LOCAL || window.location.hostname.endsWith('.sslip.io')
const isRemote = () => !isLocal()

const environment = {
  API: process.env.REACT_APP_API!,
  /* FIXME: TODO: api.candao.io deprecation */
  CANDAO_API: isProduction()
    ? 'https://api.candao.io'
    : isLocal()
    ? 'https://api.dev.candao.io'
    : 'https://api.dev.candao.io',
}

export { isProduction, isDevelopment, isLocal, isRemote, environment }
