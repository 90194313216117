import { Box, Flex, Grid, Image, Input, Spinner, Text } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { socket, useChatProvider } from '../../../../provider/Chat/chatProvider'
import { useEthereumProvider } from '../../../../provider/Ethereum/ethereumProvider'
import { Avatar } from '../../../shared/avatars/Avatar'
import { NoThreads } from './NoThreads/noThreads'
import { MobileFilters } from './MobileFilters/mobileFIlters'
import { useDebounce } from '../../../../hooks/useDebounce'
import { fetchApi } from '../../../../utils/fetcher'
import { getSocialxplorerJwtAuthKey } from '../../../../utils/auth'
import useAsyncEffect from '../../../../hooks/effects/async'
import { time_ago, trimAddress } from '../../../../utils/parser'
import { Message, User, emptyUser } from '../../../../provider/Chat/types'

const ThreadItem = ({
  id,
  lastMessageDate,
  users,
  lastMessage,
}: {
  id: number
  lastMessageDate: string
  users: User[]
  lastMessage: Message
}) => {
  const { openThread, threads } = useChatProvider()
  const navigate = useNavigate()
  const wallet = useEthereumProvider()

  const [user, setUser] = useState<User>(emptyUser)

  useEffect(() => {
    setUser(users.find(val => val.mainAddress?.toLowerCase() != wallet.account?.toLowerCase()) ?? emptyUser)
  }, [users])

  return (
    <Grid
      onClick={() => {
        navigate('/chat/messages')
        openThread(id)
      }}
      _hover={{ opacity: 0.8 }}
      cursor="pointer"
      gap="12px"
      templateColumns="auto 1fr auto"
      padding="12px 16px"
      bg="backgroundMain"
      _notLast={{
        borderBottom: '1px solid rgba(230, 232, 240, 1)',
      }}
      _last={{
        borderBottomRadius: '16px',
      }}
    >
      <Avatar
        bgColor="#eee"
        size="50px"
        image={user.avatarUrl}
        name={user.name ?? user.mainAddress}
        textSize={{ short: '14px', long: '14px' }}
      />
      <Flex
        flexDir="column"
        justify="center"
      >
        <Box
          fontWeight="500"
          color="backgroundAccent"
          fontSize="14px"
        >
          {!user.nickname ? user.nickname || trimAddress(user.mainAddress, 10) : user.name}
        </Box>
        <Flex
          fontSize="14px"
          align="center"
          gap="6px"
        >
          <Box color="textSecondary">{lastMessage.text == '_' ? 'No messages here.' : lastMessage.text}</Box>
          <Box
            boxSize="3px"
            borderRadius="50%"
            bg="textSecondary"
          ></Box>
          <Box color="textSecondary">{time_ago(lastMessageDate)}</Box>
        </Flex>
      </Flex>
    </Grid>
  )
}

export const ThreadsList = () => {
  const { threads, loading, threadsFiltered, openThread } = useChatProvider()
  const wallet = useEthereumProvider()

  const [error, setError] = useState<string>()
  const [foundUsers, setFoundUsers] = useState<any[]>([])
  const [searchFocused, setSearchFocused] = useState<boolean>()

  const [searchString, setSearchString] = useState<string>('')
  const searchThrottled = useDebounce(searchString, 500)

  const createThread = (address: string) => {
    const key = getSocialxplorerJwtAuthKey(wallet.account as address)
    const secret = localStorage.getItem(key)

    socket.emit('makeThread', { target: address, jwt: secret, address: wallet.account })

    setSearchString('')
  }

  useEffect(() => {
    setTimeout(() => {
      if (loading && threads.length == 0) {
        setError('Error loading chat data.')
      }
    }, 15000)
  }, [])

  const [throttleLoading, setThrottleLoading] = useState<boolean>(false)

  useAsyncEffect(async () => {
    if (searchThrottled.length > 2) {
      setThrottleLoading(true)
      const data = await fetchApi(`/search/${searchThrottled}`)

      let alreadyMessagingUsers: any = []

      for (let t of threads) {
        alreadyMessagingUsers = alreadyMessagingUsers.concat(t.users)
      }

      let foundUsers: any = []

      for (let u of data.profile) {
        if (alreadyMessagingUsers.filter((user: any) => user.mainAddress == u.address).length <= 0) {
          foundUsers = foundUsers.concat([u])
        }
      }

      setFoundUsers(foundUsers)
      setThrottleLoading(false)
    }
  }, [searchThrottled])

  return (
    <Box>
      <Box
        sx={{
          borderRadius: '16px',
          border: '1px solid',
          borderColor: 'borderPrimary',
          flexDirection: 'column',
          bg: 'backgroundMain',
          mx: { base: '16px', md: '0' },
          mb: { base: '16px', md: '18px' },
        }}
      >
        <Flex
          w="100%"
          gap="12px"
          justifyContent="flex-start"
          p="16px"
          alignItems="center"
          borderRadius="16px"
        >
          <Text
            color="textPrimary"
            fontWeight="600"
            fontSize="18px"
          >
            Chat
          </Text>
        </Flex>
      </Box>
      <Flex
        mb={{ base: '20px', md: '0px' }}
        flexDir="column"
        pos="relative"
        w="100%"
        border="1px solid"
        borderColor="rgba(230, 232, 240, 1)"
        borderRadius="16px"
      >
        <Flex
          align="center"
          justify="space-between"
          px="16px"
          h="56px"
          borderBottom="1px solid"
          borderColor="rgba(230, 232, 240, 1)"
        >
          <Flex>
            <Image
              w="14px"
              src="/assets/icons/zoom.svg"
            />
            <Input
              fontSize="14px"
              _placeholder={{ color: 'textSecondary' }}
              value={searchString}
              onChange={e => setSearchString(e.target.value)}
              placeholder="Search..."
              onFocus={e => setSearchFocused(true)}
              border="none"
              _focus={{ outline: 'none !important', boxShadow: 'none !important', border: 'none !important' }}
            />
            {searchFocused && searchThrottled.length > 2 && (
              <Flex
                flexDir="column"
                borderY="1px solid"
                borderTopColor="rgba(230, 232, 240, 1) !important"
                borderBottomColor="rgba(230, 232, 240, 1) !important"
                bg="backgroundMain"
                left="0"
                top="50px"
                zIndex="1"
                pos="absolute"
                w="100%"
              >
                {foundUsers.length > 0 ? (
                  foundUsers.map(user => {
                    if (user.address_[0].toLowerCase() == wallet.account?.toLowerCase()) {
                      return
                    }
                    return (
                      <Grid
                        onClick={() => createThread(user.address_[0])}
                        cursor="pointer"
                        _hover={{ bg: 'backgroundTertiary' }}
                        borderTop="1px solid rgba(230, 232, 240, 1)"
                        templateColumns="40px auto"
                        padding="14px"
                        gap="12px"
                        alignItems="center"
                      >
                        <Avatar
                          bgColor="#eee"
                          size="40px"
                          name={user ? user.name ?? user.address : 'X'}
                          image={user ? user.avatar : null}
                          textSize={{ short: '14px', long: '14px' }}
                        />
                        <Flex
                          flexDir="column"
                          gap="4px"
                        >
                          <Box
                            lineHeight="100%"
                            fontSize="12px"
                            color="textQuaternary"
                            fontWeight="500"
                          >
                            {user
                              ? user.nickname != ''
                                ? user.nickname
                                : user.name == ''
                                ? user.address_[0]
                                : user.name
                              : 'Loading'}
                          </Box>
                          <Box
                            lineHeight="100%"
                            fontSize="12px"
                            color="#8F95B2"
                            fontWeight="400"
                          >
                            {user.address_[0] ?? 'Loading'}
                          </Box>
                        </Flex>
                      </Grid>
                    )
                  })
                ) : throttleLoading ? (
                  <Flex
                    padding="14px"
                    justify="center"
                  >
                    <Spinner />
                  </Flex>
                ) : (
                  <Flex
                    padding="14px"
                    opacity="0.7"
                  >
                    No users found
                  </Flex>
                )}
              </Flex>
            )}
          </Flex>
          <Flex gap="8px">
            <Flex
              _hover={{ opacity: 0.8 }}
              cursor="pointer"
              align="center"
              justify="center"
              boxSize="32px"
              border="1px solid"
              borderColor="rgba(230, 232, 240, 1)"
              borderRadius="8px"
            >
              <Image src="/assets/icons/dots.svg" />
            </Flex>
            <Flex
              _hover={{ opacity: 0.8 }}
              cursor="pointer"
              align="center"
              bg="#3366FF"
              justify="center"
              boxSize="32px"
              borderRadius="8px"
            >
              <Image src="/assets/icons/send.svg" />
            </Flex>
          </Flex>
        </Flex>
        <MobileFilters />
        <Flex
          flexDir="column"
          overflowY="scroll"
          maxH="60vh"
        >
          {loading ? (
            <Flex
              justify="center"
              textAlign="center"
              my="40px"
            >
              {error ? <Box maxW="80vw">{error}</Box> : <Spinner />}
            </Flex>
          ) : threadsFiltered.length > 0 ? (
            threadsFiltered.map((thread: any) => {
              return (
                <ThreadItem
                  key={thread.id}
                  id={thread.id}
                  lastMessageDate={thread.lastMessage.date}
                  lastMessage={thread.lastMessage}
                  users={thread.users}
                />
              )
            })
          ) : (
            <NoThreads />
          )}
        </Flex>
      </Flex>
    </Box>
  )
}
