import { Flex, Spinner, Text } from '@chakra-ui/react'
import { FC, useState } from 'react'
import { CommentInput } from './CommentInput/CommentInput'
import { IPostSchema } from '../../../../../deafult/Default'
import useAsyncEffect from '../../../../../../../../../hooks/effects/async'
import { fetchApi } from '../../../../../../../../../utils/fetcher'
import { Post } from '../Post'

interface IProps {
  thread: IPostSchema
  isNested: boolean
}

export const CommentsSection: FC<IProps> = ({ thread, isNested }) => {
  const [comments, setComments] = useState<IPostSchema[]>([])
  const [areAllShown, setAreAllShown] = useState(false)

  const fetchComments = async () => {
    const posts = (await fetchApi('/post/replies', { thread: thread?.ipfs })) as IPostSchema[]
    setComments(posts)
  }

  useAsyncEffect(fetchComments, [])

  return (
    <Flex
      direction="column"
      onClick={(e: any) => e.stopPropagation()}
    >
      <CommentInput
        thread={thread}
        updateComments={fetchComments}
      />
      <Flex
        align="center"
        justify="space-between"
        flexDirection="column"
      >
        {!comments ? (
          <Flex p="16px">
            <Spinner />
          </Flex>
        ) : (
          comments.length > 0 &&
          (areAllShown || comments.length <= 3
            ? comments?.map(comment => (
                <Post
                  key={comment?.ipfs}
                  data={comment}
                  isNested={true}
                />
              ))
            : [comments[0], comments[1], comments[2]]?.map(comment => (
                <Post
                  key={comment?.ipfs}
                  data={comment}
                  isNested={true}
                />
              )))
        )}
        {comments?.length > 3 && (
          <Text
            p="16px"
            opacity=".66"
            fontSize="13px"
            onClick={() => setAreAllShown(prev => !prev)}
            textDecoration="underline"
            cursor="pointer"
          >
            {areAllShown ? 'Show less' : 'Show more'}
          </Text>
        )}
      </Flex>
    </Flex>
  )
}
