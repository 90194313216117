import { Button, Flex, Image, Input, Text, useToast } from '@chakra-ui/react'
import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'
import { Stage } from '../Onboarding'
import { useAccountProfileProvider } from '../../../../provider/AccountProfile/context'
import { getCandaoJwtRefreshKey, getCandaoJwtAuthKey } from '../../../../utils/auth'
import { fetcher } from '../../../../utils/fetcher'
import { environment } from '../../../../utils/environment'
import { loaded } from '../../../../utils/process'

interface ICreateProfileProps {
  setStage: Dispatch<SetStateAction<Stage>>
  phoneNumber: string
  verifyPhoneNumber: (code: string) => Promise<object | string>
}

export const SmsCodeVerification: FC<ICreateProfileProps> = ({ setStage, phoneNumber, verifyPhoneNumber }) => {
  const [isCodeCorrect, setIsCodeCorrect] = useState<boolean | undefined>(undefined)
  const [isContinueButtonDisabled, setIsContinueButtonDisabled] = useState(true)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [code, setCode] = useState<string>('')
  const toast = useToast()

  const { address_ } = useAccountProfileProvider()

  const [secondsLeft, setSecondsLeft] = useState(30)

  useEffect(() => {
    setTimeout(() => {
      if (secondsLeft > 0) setSecondsLeft((prevState: number) => prevState - 1)
    }, 1000)
  }, [secondsLeft])

  const handleResend = async () => {
    if (secondsLeft > 0) return
    else {
      await loaded(async () => {
        /* FIXME: TODO: api.candao.io deprecation */
        await fetcher(`${environment.CANDAO_API}/api/auth/send-phone-code`, {
          phone_number: phoneNumber,
        })
        setSecondsLeft(30)
      })
    }
  }

  const handleCodeInput = (e: any) => {
    setIsCodeCorrect(undefined)
    setIsContinueButtonDisabled(e.target.value === '')
    setCode(e.target.value)
  }

  const handleContinue = () => {
    setIsSubmitting(true)
    verifyPhoneNumber(code).then((res: any) => {
      if (typeof res == 'string') {
        setIsCodeCorrect(false)
        toast({ title: res, status: 'error', isClosable: false })
      } else if (res.status === 422) {
        res?.errors?.forEach((err: string) => {
          toast({ title: err, status: 'error', isClosable: true })
        })
      } else {
        setLocalStorageData(address_[0], res as IData)
        setIsCodeCorrect(true)
        setStage(Stage.CREATE_PROFILE)
      }
      setIsSubmitting(false)
    })
  }

  return (
    <Flex
      flexDir="column"
      gap="32px"
      align="start"
      w="100%"
    >
      <Flex
        bgColor="#FFEFD2"
        borderRadius="16px"
        align="center"
        justify="center"
        p="16px"
      >
        <Image src="/assets/icons/phone-add.svg" />
      </Flex>
      <Flex flexDir="column">
        <Text
          color="backgroundAccent"
          fontSize="20px"
          fontWeight="600"
        >
          Enter the code
        </Text>
        <Text
          color="textQuaternary"
          fontSize="14px"
        >
          Please, type the verification code sent to {phoneNumber}
        </Text>
      </Flex>
      <Flex
        flexDir="column"
        gap="8px"
        w="100%"
      >
        <Text
          color="backgroundAccent"
          fontSize="14px"
          fontWeight="500"
        >
          Verification code
        </Text>
        <Input
          placeholder="Enter the number"
          color="textQuaternary"
          onChange={handleCodeInput}
          borderColor="#D8DAE5"
          _hover={{ borderColor: '#D8DAE5' }}
        />
        <Text
          fontSize="14px"
          color="#D14343"
          opacity={isCodeCorrect === false ? '1' : '0'}
        >
          Incorrect code
        </Text>
      </Flex>
      <Flex
        w="100%"
        justify="space-between"
        align="center"
        fontSize="12px"
      >
        <Text color="textSecondary">0:{secondsLeft < 10 ? '0' + secondsLeft : secondsLeft} to resend the code</Text>
        <Text
          color={secondsLeft > 0 ? 'textSecondary' : 'textBlue'}
          onClick={handleResend}
          userSelect="none"
          _hover={{
            textDecoration: secondsLeft > 0 ? 'none' : 'underline',
            cursor: secondsLeft > 0 ? 'not-allowed' : 'pointer',
          }}
        >
          Resend
        </Text>
      </Flex>
      <Flex
        w="100%"
        align="center"
        justify="flex-end"
        gap="8px"
      >
        <Button
          variant="light"
          borderColor="#D8DAE5"
          fontSize="12px"
          onClick={() => {
            setIsCodeCorrect(false)
            setIsContinueButtonDisabled(true)
            setStage(Stage.PHONE_VERIFICATION)
          }}
        >
          Back
        </Button>
        <Button
          bgColor="textBlue"
          isDisabled={isContinueButtonDisabled}
          onClick={handleContinue}
          isLoading={isSubmitting}
          fontSize="12px"
          color="backgroundMain"
          variant="dark"
        >
          Verify
        </Button>
      </Flex>
    </Flex>
  )
}

interface IData {
  address: string
  access_token: string
  refresh_token: string
}

function setLocalStorageData(address: address, data: IData) {
  localStorage.setItem(getCandaoJwtAuthKey(address), data.access_token)
  localStorage.setItem(getCandaoJwtRefreshKey(address), data.refresh_token)
}
