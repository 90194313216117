import { Box, Button, Flex, Grid, Image, Input, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react'
import EmojiPicker from 'emoji-picker-react'
import { useEffect, useMemo, useState } from 'react'
import { socket, useChatProvider } from '../../../../../provider/Chat/chatProvider'
import { useEthereumProvider } from '../../../../../provider/Ethereum/ethereumProvider'
import { getAuthHeaders, getSocialxplorerJwtAuthKey } from '../../../../../utils/auth'
import axios from 'axios'
import { fetchApi } from '../../../../../utils/fetcher'
import { useAccountProfileProvider } from '../../../../../provider/AccountProfile/context'
import { AddressZero } from '../../../../../utils/ethereum'

export const InputModule = ({ small = false, threadId }: { small?: boolean; threadId: string | null }) => {
  const [newMessage, setNewMessage] = useState<string>()

  const { selectedThreadId, getChatUser, users, currentUser, getThreadById } = useChatProvider()
  const wallet = useEthereumProvider()

  const [assets, setAssets] = useState<any[]>([])

  const handleEmoji = (emoji: any) => {
    setNewMessage(prev => (prev || '') + emoji.emoji)
  }

  const onFileChange = (e: any) => {
    if (e) {
      const file = e.target.files[0]

      // Update the formData object

      // Request made to the backend api
      // Send formData object

      const reader = new FileReader()
      reader.onload = async function (evt: any) {
        const contents = evt.target.result

        const blob = await (await fetch(reader.result as any)).blob()
        const fileParsed = new File([blob], file.name)

        const formData = new FormData()
        formData.append('file', fileParsed)

        fetchApi('/post/uploadAsset', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }).then(res => {
          setAssets([{ type: 'image', path: res.path, name: file.name }])
        })
      }
      reader.readAsDataURL(file)
    }
  }

  const handleSubmit = () => {
    if (threadId == null) return
    const key = getSocialxplorerJwtAuthKey(wallet.account as address)
    const secret = localStorage.getItem(key)

    socket.emit('data', {
      content: newMessage,
      thread: threadId,
      jwt: secret,
      address: getThreadById(selectedThreadId!)?.users[0].mainAddress ?? AddressZero,
      assets: assets,
    })

    setAssets([])
    setNewMessage('')
  }

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      handleSubmit()
    }
  }

  const handleMessageChange = (e: any) => {
    setNewMessage(e.target.value)
  }

  const emojiPickerDom = useMemo(
    () => (
      <EmojiPicker
        previewConfig={{ showPreview: false }}
        onEmojiClick={handleEmoji}
        lazyLoadEmojis
        searchDisabled={true}
      />
    ),
    []
  )

  return (
    <Grid
      w="100%"
      bg={small ? 'backgroundMain' : 'rgba(249, 250, 252, 1)'}
      borderTop="1px solid rgba(230, 232, 240, 1)"
      padding={small ? '0px' : '16px'}
      pos="absolute"
      bottom="0"
      borderBottomRadius="inherit"
    >
      <Grid
        templateColumns={small ? 'auto' : 'auto 40px'}
        gap="8px"
        padding={small ? '2px' : '0px'}
      >
        <Grid
          bg="backgroundMain"
          w="100%"
          templateColumns="auto 1fr auto"
          borderRadius={small ? '0px' : '8px'}
          border={small ? '0px' : '1px solid'}
          borderColor="rgba(230, 232, 240, 1)"
        >
          <Flex mx="12px">
            <Image
              cursor="pointer"
              src="/assets/icons/paperclip.svg"
              onClick={() => (window as any).document.getElementById('file_input').click()}
            />

            <Input
              display="none"
              type="file"
              accept="image/*"
              id="file_input"
              onChange={onFileChange}
            />
          </Flex>
          <Input
            fontSize="14px"
            _placeholder={{ color: 'rgba(143, 149, 178, 1)' }}
            paddingX="0px"
            value={newMessage}
            onChange={handleMessageChange}
            placeholder="Write a message..."
            onKeyDown={e => {
              handleKeyDown(e)
            }}
            border="none"
            boxShadow="none"
            outline="none"
            _focus={{ outline: 'none !important', boxShadow: 'none !important', border: 'none !important' }}
          />
          <Flex>
            <Menu
              closeOnSelect={false}
              flip={true}
            >
              <MenuButton>
                <Image
                  cursor="pointer"
                  mx="12px"
                  src="/assets/icons/mood-smile.svg"
                />
              </MenuButton>
              <MenuList
                border="none"
                padding="0px"
                margin="none"
                bg="none"
              >
                <MenuItem
                  bg="none"
                  padding="0px"
                  _hover={{ bg: 'none' }}
                  border="none !important"
                >
                  {emojiPickerDom}
                </MenuItem>
              </MenuList>
            </Menu>
          </Flex>
        </Grid>
        {!small && (
          <Button
            onClick={handleSubmit}
            _hover={{ boxShadow: 'none', opacity: '0.8' }}
            boxSize="40px"
            padding="0px"
            bg="rgba(51, 102, 255, 1)"
          >
            <Image src="/assets/icons/send.svg" />
          </Button>
        )}
      </Grid>
      {assets.length > 0 && (
        <Flex
          flexDir="column"
          gap="8px"
          borderTop={small ? '1px' : '0px'}
          borderColor="borderPrimary"
          mt={small ? '4px' : '20px'}
          padding={small ? '4px' : '0px'}
          maxH="100px"
          overflowX="scroll"
        >
          {assets.map(asset => {
            return (
              <Grid
                h="32px"
                templateColumns="32px 1fr auto"
                alignItems="center"
                gap="12px"
              >
                <Flex
                  borderRadius="4px"
                  bg="backgroundMain"
                  boxSize="32px"
                  border="1px solid"
                  align="center"
                  justify="center"
                  borderColor="borderPrimary"
                >
                  <Image src="/assets/icons/file.svg" />
                </Flex>
                <Flex
                  fontSize="12px"
                  color="textQuaternary"
                  overflow="hidden"
                >
                  {asset.name.slice(0, 10)} ... {asset.name.slice(-10)}
                </Flex>
                <Image
                  w="20px"
                  onClick={() => {
                    setAssets(assets => assets.filter(newAsset => newAsset != asset))
                  }}
                  cursor="pointer"
                  mr="6px"
                  src="/assets/icons/trash.svg"
                />
              </Grid>
            )
          })}
        </Flex>
      )}
    </Grid>
  )
}
