import {
  Box,
  Divider,
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  UseDisclosureProps,
  useToast,
} from '@chakra-ui/react'
import React, { FC, useEffect, useRef, useState } from 'react'
import QRCode from 'react-qr-code'
import { copyToClipboard } from '../../../../utils/os'
import useAsyncEffect from '../../../../hooks/effects/async'
import { loaded } from '../../../../utils/process'
import SpinnerText from '../../../shared/tags/SpinnerText'
import { fetcherGet } from '../../../../utils/fetcher'
import { getCandaoJwtAuthKey } from '../../../../utils/auth'
import { useEthereumProvider } from '../../../../provider/Ethereum/ethereumProvider'
import { environment } from '../../../../utils/environment'
import QRCodeStyling from 'qr-code-styling'

interface IProps {
  disclosure: UseDisclosureProps
}

export const UserCode: FC<IProps> = ({ disclosure }) => {
  const [accessCode, setAccessCode] = useState('')
  const [referralLink, setReferralLink] = useState('')

  const toast = useToast()
  const { account, isAuthed } = useEthereumProvider()

  const ref = useRef<HTMLDivElement>(null)

  const qrCode = new QRCodeStyling({
    width: 300,
    height: 300,
    data: referralLink || 'https://socialtokensdev.page.link/PHoiPW5N5W6SKpgH8',
    margin: 0,
    type: 'svg',
    qrOptions: { typeNumber: 0, mode: 'Byte', errorCorrectionLevel: 'L' },
    imageOptions: { hideBackgroundDots: true, imageSize: 0.4, margin: 0 },
    dotsOptions: { type: 'classy', color: 'textQuaternary' },
    backgroundOptions: { color: '#ffffff' },
    cornersSquareOptions: { color: 'textQuaternary' },
    cornersDotOptions: { color: '#3366ff' },
  })

  useEffect(() => {
    setTimeout(() => {
      qrCode.append(ref!.current as any)
    }, 100)
  }, [disclosure.isOpen])

  const handleCopyAccess = async () => {
    if (!accessCode) {
      toast({ title: `Please wait, loading referral…` })
      return
    }

    await copyToClipboard(accessCode)
    toast({ title: `Copied!` })
  }

  const handleCopyReferral = async () => {
    if (!referralLink) {
      toast({ title: `Please wait, loading referral…` })
      return
    }

    await copyToClipboard(referralLink)
    toast({ title: `Copied!` })
  }

  /** Fetch referral backend data */
  useAsyncEffect(async () => {
    if (!account) {
      return
    }

    const storageKey = localStorage.getItem(getCandaoJwtAuthKey(account as address))

    if (!storageKey) {
      return
    }

    void loaded(async () => {
      /* FIXME: TODO: api.candao.io deprecation */
      // const accessCodeData = await fetcherGet(`${environment.CANDAO_API}/api/access-code`, {
      //   headers: {
      //     Authorization: `Bearer ${storageKey}`,
      //   },
      // })

      // setAccessCode(accessCodeData?.access_codes?.[0].code)
      // setReferralLink(accessCodeData?.access_codes?.[0].referral_link)
    })
  }, [account, isAuthed])

  return (
    <Modal
      isOpen={disclosure.isOpen ?? false}
      onClose={disclosure.onClose!}
      size="sm"
    >
      <ModalOverlay />
      <ModalContent
        bgColor="backgroundMain"
        color="backgroundAccent"
        mt={{ base: '72px', sm: '7vh' }}
        position="relative"
        marginBottom={{ base: '10px', md: '64px' }}
        mx="10px"
      >
        <ModalHeader>
          <Text>My access code</Text>
          <ModalCloseButton
            mt="8px"
            color="#8F95B2"
          />
        </ModalHeader>
        <Divider />
        <ModalBody
          display="flex"
          flexDirection="column"
          gap="24px"
          py="24px"
        >
          <Text
            fontSize="14px"
            color="textSecondary"
          >
            Scan this to get a referral link.
          </Text>
          <Flex
            w="100%"
            justifyContent="center"
            p="40px"
            borderRadius="16px"
            bgColor="backgroundTertiary"
            border="1px solid borderPrimary"
          >
            {/* <QRCode
              value={referralLink || 'https://socialtokensdev.page.link/PHoiPW5N5W6SKpgH8'}
              fgColor="textSecondary"
              bgColor="backgroundTertiary"
            /> */}
            <Box ref={ref} />
          </Flex>
          <Flex
            flexDir="column"
            gap="8px"
          >
            <Flex
              flexDir="column"
              gap="2px"
            >
              <Text
                fontSize="14px"
                fontWeight="500"
                color="backgroundAccent"
              >
                Access code
              </Text>
              <Text
                fontSize="14px"
                color="textSecondary"
              >
                This is your access code.
              </Text>
            </Flex>
            <Flex
              w="100%"
              p="10px 12px"
              align="center"
              justify="space-between"
              fontSize="14px"
              color="textQuaternary"
              border="1px solid"
              borderColor="#D8DAE5"
              borderRadius="8px"
              gap="8px"
            >
              <Text overflow="hidden">{accessCode || <SpinnerText />}</Text>
              <Image
                src="/assets/icons/copy.svg"
                boxSize=""
                cursor="pointer"
                onClick={handleCopyAccess}
              />
            </Flex>
          </Flex>
          <Flex
            flexDir="column"
            gap="8px"
          >
            <Flex
              flexDir="column"
              gap="2px"
            >
              <Text
                fontSize="14px"
                fontWeight="500"
                color="backgroundAccent"
              >
                Refferal link
              </Text>
              <Text
                fontSize="14px"
                color="textSecondary"
              >
                This is your referral link.
              </Text>
            </Flex>
            <Flex
              w="100%"
              p="10px 12px"
              align="center"
              justify="space-between"
              fontSize="14px"
              color="textQuaternary"
              border="1px solid"
              borderColor="#D8DAE5"
              borderRadius="8px"
              gap="8px"
            >
              <Text overflow="hidden">{referralLink || <SpinnerText />}</Text>
              <Image
                src="/assets/icons/copy.svg"
                boxSize=""
                cursor="pointer"
                onClick={handleCopyReferral}
              />
            </Flex>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
