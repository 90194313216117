import { Flex } from '@chakra-ui/react'
import React, { useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import useAsyncEffect from '../../../../../hooks/effects/async'
import { useAccountProfileProvider } from '../../../../../provider/AccountProfile/context'
import { getBlockedHeaders } from '../../../../../utils/auth'
import { fetchApi } from '../../../../../utils/fetcher'
import { loaded } from '../../../../../utils/process'
import { IPostSchema, PostType } from '../../../Home/MiddlePanel/deafult/Default'
import { Post } from '../../../Home/MiddlePanel/roles/tabs/discussions/Post/Post'
import { GroupContentSearch } from '../../GroupContentSearch'

export const Offers = () => {
  const [posts, setPosts] = useState<IPostSchema[]>([])
  const [isPostsLoading, setIsPostsLoading] = useState(true)
  const { id } = useParams()

  const account = useAccountProfileProvider()

  useAsyncEffect(async () => {
    await loaded(async () => {
      const res = await fetchApi(
        '/post/getGroups',
        { groups: [id], filters: ['quest'] },
        {
          headers: getBlockedHeaders(account.blockedUsers),
        }
      )

      setPosts(res)
    }, setIsPostsLoading)
  }, [])

  return (
    <Flex sx={{ flexDir: 'column', gap: '16px' }}>
      <GroupContentSearch
        placeholder="Search for offers"
        searchAction={() => {}}
        isDisabled
      />
      {posts?.map((post: IPostSchema) => (
        <Post
          key={post.ipfs}
          data={post}
        />
      ))}
    </Flex>
  )
}
