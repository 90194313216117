import { useState } from 'react'
import { Box, Flex, Text } from '@chakra-ui/react'
import useAsyncEffect from '../../../../hooks/effects/async'
import SpinnerText from '../../../shared/tags/SpinnerText'
import { fetchApi } from '../../../../utils/fetcher'
import get from 'lodash/get'
import { AreaGradientChart } from '../../../shared/charts/AreaGradientChart'
import { IRateAccumulationItem, IRateGlobal } from '../MainInfo/mainInfo'
import { loaded } from '../../../../utils/process'
import moment from 'moment'
import useColor from '../../../../hooks/chakra/useColor'

enum Tabs {
  DAY,
  WEEK,
  MONTH,
  YEAR,
}

const tabs = [
  { id: Tabs.DAY, label: '24h', data: 24 },
  { id: Tabs.WEEK, label: 'W', data: 24 * 7 },
  { id: Tabs.MONTH, label: 'M', data: 24 * 31 },
  { id: Tabs.YEAR, label: 'Y', data: 24 * 365 },
]

export interface IRate {
  rate: number
  change: number
}

export const DailyProfiles = () => {
  const [state, setState] = useState<{ [key in 'active' | 'new']: IRate }>()
  const [rates, setRates] = useState<IRateAccumulationItem[]>([])

  const [activeTab, setActiveTab] = useState(Tabs.YEAR)

  const colorTabActive = useColor('backgroundMain')
  const colorBackgroundTabActive = useColor('textPrimary')

  /** Stats fetch */
  useAsyncEffect(
    () =>
      loaded(async () => {
        // const ratesData = (await fetchApi('/rate', { range: get(tabs, activeTab).data })) as IRateGlobal
        const ratesData = (await fetchApi('/rate', { range: get(tabs, activeTab).data })) as IRateGlobal
        setRates(ratesData.profiles.sort((a, b) => moment(a._id).unix() - moment(b._id).unix()))
        // todo: change (get last <data span> count, and subtract current from it; yield the increase-percent then)
        setState({
          active: { rate: ratesData.onboardedProfiles.length, change: 100 },
          new: { rate: ratesData.profiles.reduce((acc, curr) => acc + curr.count, 0), change: 100 },
        })
      }),
    [activeTab]
  )

  const data = {
    values: [1, 2, 2, 3],
    keys: [new Date(28, 12, 2001), new Date(29, 12, 2001), new Date(30, 12, 2001), new Date()],
  }

  return (
    <Flex
      flexDirection="column"
      w={{ base: 'unset', lg: 'auto' }}
      justifyContent="space-between"
    >
      <Flex
        justifyContent="space-between"
        w="100%"
        alignItems="center"
        gap="12px"
      >
        <Text
          color="backgroundAccent"
          fontWeight="bold"
          whiteSpace="nowrap"
        >
          Daily Profiles
        </Text>
        <Flex
          borderRadius="8px"
          border="1px solid"
          borderColor="#D0D5DD"
        >
          {tabs.map(tab => (
            <Box
              key={tab.id}
              _last={{ borderRightRadius: '7px' }}
              _first={{ borderLeftRadius: '7px' }}
              cursor="pointer"
              style={{
                ...(tab.id === activeTab ? { background: colorBackgroundTabActive, color: colorTabActive } : {}),
              }}
              p="6px 16px"
              display="flex"
              alignItems="center"
              justifyContent="center"
              color="#8F95B2"
              onClick={() => setActiveTab(tab.id)}
            >
              {tab.label}
            </Box>
          ))}
        </Flex>
      </Flex>
      <Flex>
        <AreaGradientChart
          data={{
            values: [...rates.map(item => item.count)].reduce<Array<number>>(
              (acc, curr) => [...acc, curr + (acc.length > 0 ? acc[acc.length - 1] : 0)],
              []
            ),
            keys: rates.map(item => item._id),
          }}
          color="#3366FF"
        />
      </Flex>
      <Flex
        pt="16px"
        gridGap="8px"
      >
        <Flex
          flexDirection="column"
          gridGap="4px"
          p="8px 16px"
          border="1px solid"
          borderColor="borderPrimary"
          borderRadius="8px"
          width="100%"
        >
          <Text
            color="textSecondary"
            fontSize="12px"
          >
            Active Accounts
          </Text>
          <Flex gridGap="8px">
            <Text
              color="backgroundAccent"
              fontSize="16px"
              fontWeight="bold"
            >
              {state?.active.rate ?? <SpinnerText />}
            </Text>
            <Box
              display="flex"
              borderRadius="4px"
              alignItems="center"
              justifyContent="center"
              p="2px 6px"
              color="#7D2828"
              fontSize="10px"
              bgColor="#F9DADA"
            >
              +{state?.active.change}%
            </Box>
          </Flex>
        </Flex>
        <Flex
          flexDirection="column"
          gridGap="4px"
          p="8px 16px"
          border="1px solid"
          borderColor="borderPrimary"
          borderRadius="8px"
          width="100%"
        >
          <Text
            color="textSecondary"
            fontSize="12px"
          >
            New Accounts
          </Text>
          <Flex gridGap="8px">
            <Text
              color="backgroundAccent"
              fontSize="16px"
              fontWeight="bold"
            >
              {state?.new.rate ?? <SpinnerText />}
            </Text>
            <Box
              display="flex"
              borderRadius="4px"
              alignItems="center"
              justifyContent="center"
              p="2px 6px"
              color="#317159"
              fontSize="10px"
              bgColor="#DCF2EA"
            >
              +{state?.new.change}%
            </Box>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default DailyProfiles
