import {
  Button,
  Divider,
  Flex,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  useDisclosure,
  UseDisclosureReturn,
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { isAddress } from 'viem'
import useAsyncEffect from '../../../../hooks/effects/async'
import { useDebounce } from '../../../../hooks/useDebounce'
import { emptyProfile } from '../../../../provider/Profile/profileProvider'
import { fetchApi } from '../../../../utils/fetcher'
import { getInitials } from '../../../../utils/parser'
import { loaded } from '../../../../utils/process'
import { UserAvatar } from '../../../shared/avatars/UserAvatar'
import { CodeView } from './CodeView'
import { EmailView } from './EmailView'
import { LinkView } from './LinkView'
import { ViewSwitcher } from './ViewSwitcher'

export const InviteModal = ({ disclosure }: { disclosure: UseDisclosureReturn }) => {
  const [search, setSearch] = useState('')
  const [results, setResults] = useState<any[]>([])
  const [suggested, setSuggested] = useState<any[]>([])
  const [isLoading, setIsLoading] = useState(false)

  const emailDisclosure = useDisclosure()
  const linkDisclosure = useDisclosure()
  const codeDisclosure = useDisclosure()

  const searchThrottled = useDebounce(search, 500)

  const handleSearch = (e: any) => {
    setSearch(e.target.value)
  }

  useAsyncEffect(async () => {
    if (searchThrottled.length < 2) {
      setResults(suggested)
      setIsLoading(false)
      return
    }

    void loaded(async () => {
      const data = await fetchApi(`/search/${searchThrottled}`)
      /** Display account if not fetched and pasted in */
      if (
        isAddress(searchThrottled) &&
        !data.profile.flatMap((element: any) => element.address_?.[0]).includes(searchThrottled.toLowerCase())
      ) {
        data.profile.push({
          ...emptyProfile,
          address_: [searchThrottled.toLowerCase()],
          address: searchThrottled.toLowerCase(),
          name: 'No name',
          _created_at: new Date(),
          _updated_at: new Date(),
          artificial: true,
        })
      }
      setResults(data.profile ?? [])
    }, setIsLoading)
  }, [searchThrottled])

  useAsyncEffect(async () => {
    void loaded(async () => {
      const data = await fetchApi(`/search/0x`)
      /** Display account if not fetched and pasted in */
      if (
        isAddress(searchThrottled) &&
        !data.profile.flatMap((element: any) => element.address_?.[0]).includes(searchThrottled.toLowerCase())
      ) {
        data.profile.push({
          ...emptyProfile,
          address_: [searchThrottled.toLowerCase()],
          address: searchThrottled.toLowerCase(),
          name: 'No name',
          _created_at: new Date(),
          _updated_at: new Date(),
          artificial: true,
        })
      }
      setResults(data.profile ?? [])
      setSuggested(data?.profile ?? [])
    }, setIsLoading)
  }, [])

  return (
    <>
      <EmailView
        disclosure={emailDisclosure}
        parentDisclosure={disclosure}
      />
      <LinkView
        disclosure={linkDisclosure}
        parentDisclosure={disclosure}
      />
      <CodeView
        disclosure={codeDisclosure}
        parentDisclosure={disclosure}
      />
      <Modal
        isOpen={disclosure.isOpen}
        onClose={disclosure.onClose}
        size="lg"
      >
        <ModalOverlay />
        <ModalContent
          mx="10px"
          marginBottom={{ base: '10px', md: '64px' }}
        >
          <ModalHeader
            color="backgroundAccent"
            fontSize="18px"
            fontWeight="600"
          >
            Invite your friends
          </ModalHeader>
          <Divider />
          <ModalCloseButton mt="8px" />
          <ModalBody
            display="flex"
            flexDir="column"
            p="24px"
            gap="24px"
          >
            <Flex
              flexDir="column"
              gap="16px"
              w="100%"
            >
              <ViewSwitcher
                onClick={() => {
                  disclosure.onClose()
                  emailDisclosure.onOpen()
                }}
                title="Invite via email"
                text="Send an email invitation to your friends"
                imageUri="/assets/icons/email.svg"
              />
              <ViewSwitcher
                onClick={() => {
                  disclosure.onClose()
                  linkDisclosure.onOpen()
                }}
                title="Invite via link"
                text="Share group link to your friends"
                imageUri="/assets/icons/link.svg"
              />
              <ViewSwitcher
                onClick={() => {
                  disclosure.onClose()
                  codeDisclosure.onOpen()
                }}
                title="Share via QR code"
                text="Share the group link using your QR code."
                imageUri="/assets/icons/qr.svg"
              />
            </Flex>
            <InputGroup>
              <Input
                placeholder="Search for friends to invite"
                _placeholder={{ color: '#8F95B2' }}
                onChange={handleSearch}
                value={search}
              />
              <InputRightElement>
                <Image src="/assets/icons/search.svg" />
              </InputRightElement>
            </InputGroup>
            <Flex
              flexDir="column"
              gap="16px"
              w="100%"
            >
              <Text
                fontSize="16px"
                fontWeight="600"
                color="backgroundAccent"
                mb="8px"
              >
                Suggested
              </Text>
              {isLoading && <Spinner />}
              {results?.map((result: any, index: number) => (
                <Flex
                  key={`${result?.address}_${index}}`}
                  w="100%"
                  align="center"
                  justify="space-between"
                  sx={{
                    cursor: 'default',
                  }}
                >
                  <Flex
                    gap="8px"
                    align="center"
                  >
                    <UserAvatar
                      bgColor="#F8E3DA"
                      size="40px"
                      image={result?.avatar}
                    >
                      {(!result?.avatar || result?.avatar.length < 35) && (
                        <Text
                          color="#2952CC"
                          textTransform="uppercase"
                        >
                          {getInitials(result?.name)}
                        </Text>
                      )}
                    </UserAvatar>
                    <Flex flexDir="column">
                      <Text
                        fontSize="14px"
                        color="backgroundAccent"
                      >
                        {result?.name && result?.name.length > 0 ? result.name : 'No name'}
                      </Text>
                      {result?.nickname && result?.nickname.length > 0 && (
                        <Text
                          fontSize="12px"
                          color="textSecondary"
                        >
                          @{result?.nickname}
                        </Text>
                      )}
                    </Flex>
                  </Flex>
                  <Button
                    h="32px"
                    fontSize="12px"
                    onClick={() => null}
                    isDisabled
                  >
                    Invite
                  </Button>
                </Flex>
              ))}
            </Flex>
          </ModalBody>
          <Divider />
          <ModalFooter>
            <Button
              variant="dark"
              onClick={disclosure.onClose}
              h="32px"
              fontSize="12px"
            >
              Done
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
