import { Button, Checkbox, Flex, Image, Input, Link, Spinner, Text, useToast } from '@chakra-ui/react'
import { useState } from 'react'
import { Wrapper } from '../wrapper'

const VALID_PASS = 'Validpass'
const VALID_PASS_KEY = '2e5a2d36-410d-4ce4-a35c-1d4ca0d4e93c'

export const AuthWall = () => {
  const [pass, setPass] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const toast = useToast()

  const handleSignIn = () => {
    if (pass === VALID_PASS) {
      setIsLoading(true)
      sessionStorage.setItem(VALID_PASS_KEY, VALID_PASS)

      window.location.reload()

      return
    }

    toast({ title: 'Wrong password, sorry', status: 'error' })
  }

  const handleKeyDown = (event: any) => {
    if (event.key !== 'Enter') {
      return
    }

    handleSignIn()
  }

  if (sessionStorage.getItem(VALID_PASS_KEY) === VALID_PASS) {
    return <Wrapper />
  }

  return (
    <Flex
      bgImage={{ base: 'none', lg: '/assets/icons/landing-bg.svg' }}
      bgRepeat="no-repeat"
      bgSize="contain"
      bgAttachment="fixed"
      bgPosition="bottom"
      w="100vw"
      minH="100vh"
    >
      <Flex
        p="60px 0 45px 0"
        px={{ base: '10px', md: '64px', xl: '240px' }}
        overflow="hidden"
        flexDir="column"
        m="0"
        w="100%"
        mx="auto"
        flexWrap="wrap"
        maxW="1600px"
        gap="100px"
      >
        <Flex
          mt="80px"
          flexDir="column"
          align="center"
          gap="20px"
          textAlign="center"
        >
          <Text
            fontSize="24px"
            color="textQuaternary"
          >
            Sign in
          </Text>
          <Flex sx={{ gap: '8px' }}>
            <Input
              type="password"
              value={pass}
              autoFocus
              onChange={event => setPass(event.target.value)}
              onKeyDown={event => {
                handleKeyDown(event)
              }}
            />
            <Button
              variant="dark"
              onClick={handleSignIn}
            >
              Check
            </Button>
          </Flex>
          {isLoading && <Spinner />}
        </Flex>
      </Flex>
    </Flex>
  )
}
