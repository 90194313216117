import { Box, Flex, Link as ChakraLink, Text } from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import { CardContainer } from '../../../shared/containers/CardContainer'
import { useState } from 'react'
import useAsyncEffect from '../../../../hooks/effects/async'
import { loaded } from '../../../../utils/process'
import { fetchApi } from '../../../../utils/fetcher'
import { redirect } from '../../../../utils/redirector'
import moment from 'moment/moment'
import { trimAddress } from '../../../../utils/parser'

export interface IBlock {
  id: number
  timeInSeconds: number
  recipient: string
  cdoAmount: number
  cdoInSecs: number
  addon: number
}

export const LastBlocks = () => {
  const [txs, setTxs] = useState<any[]>()

  useAsyncEffect(async () => {
    void loaded(async () => {
      try {
        const data = await fetchApi('/tx/', {})

        setTxs(data)
      } catch {
        setTxs([])
      }
    })
  }, [])

  return (
    <CardContainer>
      <Flex
        flexWrap="wrap"
        flexDir="column"
        gap="16px"
      >
        {txs?.slice(0, 5)?.map((tx, index) => {
          return (
            <Flex
              flexWrap="wrap"
              key={`${tx.hash}_${index}}`}
              textColor="textSecondary"
              justifyContent="space-between"
              alignItems="center"
            >
              <Flex
                alignItems="center"
                gridGap="8px"
              >
                <Box
                  display="flex"
                  borderRadius="8px"
                  alignItems="center"
                  justifyContent="center"
                  bg="grayPrimary"
                  w="40px"
                  h="40px"
                >
                  <Box
                    borderRadius="50%"
                    w="12px"
                    h="12px"
                    border="1.5px solid"
                    borderColor="#C1C4D6"
                  />
                </Box>
                <Flex
                  flexWrap="wrap"
                  flexDir="column"
                  gap="4px"
                  justify="center"
                  fontSize="14px"
                >
                  <Box>
                    <ChakraLink onClick={() => redirect(`https://bscscan.com/block/${tx.blockNumber}`)}>
                      {tx.blockNumber}
                    </ChakraLink>
                  </Box>
                  <Box fontSize="12px">{moment.unix(tx.blockTimestamp).fromNow()}</Box>
                </Flex>
              </Flex>

              <Flex
                flexWrap="wrap"
                flexDir="column"
                gap="4px"
                fontSize="14px"
                justify="center"
              >
                <Box
                  display="flex"
                  alignItems="center"
                  gridGap="4px"
                >
                  To{' '}
                  <Link to={`/profile/${tx.target}`}>
                    <Text> {trimAddress(tx.target)} </Text>
                  </Link>
                </Box>
                <Flex flexWrap="wrap">
                  <Box
                    display="inline"
                    _hover={{ textDecoration: 'underline' }}
                  >
                    <Link to="">{tx.cdoAmount} CDO</Link>
                  </Box>
                  <Text>&nbsp;in 3 secs</Text>
                </Flex>
              </Flex>
              <Flex
                flexWrap="wrap"
                align="center"
                justify="flex-end"
              >
                <Box
                  padding="2px 6px"
                  borderRadius="4px"
                  fontSize="10px"
                  lineHeight="12px"
                  bg="#EDEFF5"
                  color="textQuaternary"
                >
                  Block
                </Box>
              </Flex>
            </Flex>
          )
        })}
      </Flex>
    </CardContainer>
  )
}
