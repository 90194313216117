import {
  Box,
  Divider,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { _log } from '../../../../../../../../../logger'
import { AddressZero } from '../../../../../../../../../utils/ethereum'
import { fetchApi } from '../../../../../../../../../utils/fetcher'
import { buildIpfsGateway, getInitials, trimAddress, trimBalance } from '../../../../../../../../../utils/parser'
import { loaded } from '../../../../../../../../../utils/process'
import { ButtonFollow } from '../../../../../../../../shared/actions/ButtonFollow'

export const UsersModal = ({
  data,
  isOpen,
  onClose,
  title,
  token,
}: {
  data: any
  isOpen: boolean
  onClose: () => void
  title: string
  token?: string
}) => {
  const [showUserPopup, setShowUserPopup] = useState<`0x${string}`[] | undefined>(undefined)
  const [users, setUsers] = useState<any>([])
  const [loading, setIsLoading] = useState<boolean>(true)

  const fetchUsers = async () => {
    if (!data?.length) {
      return
    }

    await loaded(async () => {
      try {
        const users = await fetchApi(`/profile`, { addresses: data })
        console.log(users)
        //const userPower = await fetchApi(`/profile/power`, { address: datum, token: token })

        setUsers(users)
      } catch (error) {
        _log(error)
      }
    }, setIsLoading)
  }

  useEffect(() => {
    if (isOpen) {
      setUsers([])
      fetchUsers()
    }
  }, [data?.length || 0, isOpen])

  const handleOnHover = (address: `0x${string}`[]) => {
    setTimeout(() => {
      setShowUserPopup(address)
    }, 100)
  }

  const handleOnUnhover = (address: `0x${string}`[]) => {
    setTimeout(() => {
      setShowUserPopup(undefined)
    }, 100)
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="xs"
    >
      <ModalOverlay bg="rgba(0, 0, 0, 0.15)" />
      <ModalContent
        bgColor="backgroundMain"
        boxShadow="0px 12px 40px rgba(16, 24, 64, 0.24)"
        borderRadius="16px"
        p="0"
        mx="10px"
        marginBottom={{ base: '10px', md: '64px' }}
      >
        <ModalHeader>
          <Text
            color="backgroundAccent"
            fontSize="18px"
            fontWeight="600"
            lineHeight="24px"
          >
            {title}
          </Text>
        </ModalHeader>
        <ModalCloseButton mt="5px" />
        <Divider orientation="horizontal" />
        <ModalBody>
          <Flex
            flexDir="column"
            gap="16px"
            fontSize="14px"
            color="backgroundAccent"
            p="24px 0"
          >
            {users.map((datum: any, index: any) => {
              return (
                <Flex
                  key={`${datum.address}_${index}`}
                  w="100%"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Link
                    to={`/profile/${datum.address || AddressZero}`}
                    onMouseEnter={() => handleOnHover(datum.address_)}
                    onMouseLeave={() => handleOnUnhover(datum.address_)}
                  >
                    <Flex
                      gridGap="8px"
                      position="relative"
                    >
                      <Box
                        borderRadius="50%"
                        textTransform="uppercase"
                        display="flex"
                        w="40px"
                        h="40px"
                        alignItems="center"
                        justifyContent="center"
                        backgroundImage={buildIpfsGateway(datum.avatar)}
                        backgroundPosition="center center"
                        backgroundRepeat="no-repeat"
                        backgroundSize="cover"
                        color="#2952CC"
                        bgColor="bluePlaceholder"
                        userSelect="none"
                        zIndex={1}
                      >
                        {!datum.avatar && getInitials(datum.name || 'No name')}
                      </Box>
                      <Flex
                        flexDirection="column"
                        justifyContent="center"
                      >
                        <Flex
                          alignItems="center"
                          gridGap="5px"
                        >
                          <Text
                            fontSize="14px"
                            color={datum.name ? 'textPrimary' : 'textTertiary'}
                            sx={{
                              maxWidth: '16ch',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            }}
                          >
                            {datum.name || 'No name'}
                          </Text>
                        </Flex>
                        {datum.nickname && (
                          <Text
                            fontSize="12px"
                            color="textSecondary"
                            sx={{
                              maxWidth: '16ch',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            }}
                          >
                            @{datum.nickname}
                          </Text>
                        )}
                      </Flex>
                      {/* {showUserPopup === datum.address_ && (
                        <Delayed>
                          <UserPopup address={datum.address} />
                        </Delayed>
                      )} */}
                    </Flex>
                  </Link>
                  <ButtonFollow target={datum.address} />
                </Flex>
              )
            })}
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
