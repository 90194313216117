import { Dispatch, FC, SetStateAction, useState } from 'react'
import { Box, Flex, Image, Text, useColorMode } from '@chakra-ui/react'
import { Link, useNavigate } from 'react-router-dom'
import { useAccountProfileProvider } from '../../../../provider/AccountProfile/context'
import { useEthereumProvider } from '../../../../provider/Ethereum/ethereumProvider'
import { useChatProvider } from '../../../../provider/Chat/chatProvider'
import { Views } from '../../../../pages/Home'
import { AddressZero } from '../../../../utils/ethereum'
import useAsyncEffect from '../../../../hooks/effects/async'
import { IGroup } from '../../../../hooks/useGroup'
import { fetchApi } from '../../../../utils/fetcher'
import { loaded } from '../../../../utils/process'

interface ILeftPanel {
  setView: Dispatch<SetStateAction<Views>>
}

export const LeftPanel: FC<ILeftPanel> = ({ setView }) => {
  const user = useAccountProfileProvider()
  const wallet = useEthereumProvider()
  const { openThread } = useChatProvider()

  const { pathname } = window.location

  const [groups, setGroups] = useState<IGroup[]>([])
  const [areGroupsLoading, setAreGroupsLoading] = useState(false)

  // TODO: Keep this data in a provider not to refresh this every page change.
  useAsyncEffect(
    async () =>
      await loaded(async () => {
        if (wallet.account) {
          // TODO: This should be returned with profile data (via AccountProfileProvider)
          const data = await fetchApi(`/profile/${wallet.account}/groups`)
          setGroups(data)
        }
      }, setAreGroupsLoading),
    [wallet.account]
  )

  const navigate = useNavigate()

  const { colorMode } = useColorMode()

  return (
    <Flex
      direction="column"
      display={{ base: 'none', md: 'flex' }}
      gridGap="12px"
      minW="225px"
    >
      {/** todo refactor - this should be mapped */}
      <Link to="/">
        <Flex
          gridGap="16px"
          alignItems="center"
          borderRadius="8px"
          transition="all .15s ease-in-out"
          _hover={{ background: 'borderPrimary' }}
        >
          <Box
            display="flex"
            w="32px"
            h="32px"
            borderRadius="8px"
            border="1px solid"
            borderColor={pathname != '/' ? 'borderPrimary' : 'transparent'}
            justifyContent="center"
            alignItems="center"
            bgColor={pathname == '/' ? 'textQuaternary' : 'backgroundMain'}
          >
            <Image
              src={
                pathname == '/'
                  ? colorMode === 'light'
                    ? '/assets/icons/home-icon.svg'
                    : '/assets/icons/home-icon-dark.svg'
                  : '/assets/icons/home-icon-dark.svg'
              }
            />
          </Box>
          <Text
            color="backgroundAccent"
            fontWeight={pathname == '/' ? 500 : 400}
          >
            Home
          </Text>
        </Flex>
      </Link>
      {wallet.isAuthed && (
        <Link
          to="/chat"
          onClick={() => openThread(null)}
        >
          <Flex
            gridGap="16px"
            alignItems="center"
            borderRadius="8px"
            transition="all .15s ease-in-out"
            _hover={{ background: 'borderPrimary' }}
          >
            <Box
              display="flex"
              w="32px"
              h="32px"
              border="1px solid"
              borderColor="borderPrimary"
              borderRadius="8px"
              justifyContent="center"
              alignItems="center"
              bg="backgroundMain"
              bgColor={pathname.startsWith('/chat') ? 'textQuaternary' : 'backgroundMain'}
            >
              <Image
                sx={{
                  filter: colorMode === 'light' ? 'none' : 'contrast(0.25)',
                }}
                src={
                  !pathname.startsWith('/chat')
                    ? colorMode === 'light'
                      ? '/assets/icons/envelope.svg'
                      : '/assets/icons/envelope-light.svg'
                    : '/assets/icons/envelope.svg'
                }
              />
            </Box>
            <Text
              color="backgroundAccent"
              fontWeight={pathname.startsWith('/chat') ? 500 : 400}
            >
              Messages
            </Text>
          </Flex>
        </Link>
      )}
      <Link to={`/profile/${wallet.account ?? AddressZero}/`}>
        <Flex
          gridGap="16px"
          alignItems="center"
          borderRadius="8px"
          transition="all .15s ease-in-out"
          _hover={{ background: 'borderPrimary' }}
        >
          <Box
            display="flex"
            w="32px"
            h="32px"
            border="1px solid"
            borderColor="borderPrimary"
            borderRadius="8px"
            justifyContent="center"
            alignItems="center"
            bg="backgroundMain"
          >
            <Image src="/assets/icons/sidenav-user-icon.svg" />
          </Box>
          <Text color="textQuaternary">My profile</Text>
        </Flex>
      </Link>
    </Flex>
  )
}

export default LeftPanel
