import {
  Text,
  TabPanel,
  Tab,
  TabIndicator,
  TabList,
  TabPanels,
  Tabs,
  Flex,
  Image,
  Button,
  Link,
} from '@chakra-ui/react'
import { FC, useState } from 'react'
import { useToast } from '@chakra-ui/react'
import { Offer } from './tabs/Offer'
import { Holders } from './tabs/Holders'
import SpinnerText from '../tags/SpinnerText'
import { trimAddress } from '../../../utils/parser'
import { useNavigate } from 'react-router-dom'
import { Address } from '../../../contracts/address'

export const ICO: FC = () => {
  const toast = useToast()

  const [holdersAmount, setHoldersAmount] = useState(-1)
  const [currentTab, setCurrentTab] = useState(0)

  return (
    <Tabs
      borderX="none"
      w="calc(100% + 32px)"
      mt="12px"
      ml="-16px"
      onChange={idx => {
        setCurrentTab(idx)
      }}
      p="8px 16px"
    >
      <TabList
        w="100%"
        border="none"
        borderColor="borderPrimary"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Flex>
          <Tab
            minW="100px"
            p="8px"
            fontSize="14px"
            borderRadius="8px 8px 0px 0px"
            borderTop="1px solid"
            borderLeft="1px solid "
            borderRight="1px solid"
            borderBottom="none"
            borderColor="borderPrimary"
            mr="6px"
            _selected={{
              color: 'textBlue',
              bg: 'backgroundAccentLight',

              borderColor: 'rgba(51, 102, 255, 0.25)',
            }}
          >
            Offer
          </Tab>
          <Tab
            minW="100px"
            p="8px"
            fontSize="14px"
            borderRadius="8px 8px 0px 0px"
            borderTop="1px solid"
            borderLeft="1px solid "
            borderRight="1px solid"
            borderBottom="none"
            borderColor="borderPrimary"
            _selected={{
              color: 'textBlue',
              bg: 'backgroundAccentLight',

              borderColor: 'rgba(51, 102, 255, 0.25)',
            }}
          >
            Holders&nbsp;
            <Text
              as="span"
              sx={{ color: '#C1C4D6' }}
            >
              ({holdersAmount >= 0 ? holdersAmount : <SpinnerText />})
            </Text>
          </Tab>
        </Flex>
        <Flex sx={{ display: { base: 'none', md: 'flex' }, alignItems: 'center', gap: '4px' }}>
          <Image
            src="/assets/elements/wcdo.png"
            sx={{ boxSize: '24px' }}
          />
          <Text sx={{ color: 'textBlue', fontSize: '12px', fontWeight: '500' }}>{trimAddress(Address.W_CDO)}</Text>
          <Link
            href="https://bscscan.com/address/0x82bE056c76970e36bB7a4CE7dAA2692cB1538959"
            target="_blank"
          >
            <Image src="/assets/icons/share.svg" />
          </Link>
          <Button
            isDisabled
            onClick={() => null}
            sx={{ h: '24px', border: 'none', p: '0' }}
          >
            <Image src="/assets/icons/dots.svg" />
          </Button>
        </Flex>
      </TabList>

      <TabPanels w="100%">
        <TabPanel
          w="100%"
          sx={{ outline: 'none !important' }}
        >
          <Offer />
        </TabPanel>
        <TabPanel
          w="100%"
          sx={{ outline: 'none !important' }}
        >
          <Holders setHoldersAmount={setHoldersAmount} />
        </TabPanel>
      </TabPanels>
    </Tabs>
  )
}
