import { Flex, Image, Input, Popover, Text, useDisclosure, useOutsideClick } from '@chakra-ui/react'
import { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from 'react'
import { SearchResults } from './searchResults/SearchResults'
import { useDebounce } from '../../../../hooks/useDebounce'
import useAsyncEffect from '../../../../hooks/effects/async'
import { loaded } from '../../../../utils/process'
import { fetchApi, fetcher } from '../../../../utils/fetcher'
import { useLocation, useNavigate } from 'react-router-dom'
import { emptyProfile } from '../../../../provider/Profile/profileProvider'
import { isAddress } from 'viem'
import { environment } from '../../../../utils/environment'
import { _log } from '../../../../logger'

export interface IResults {
  tx: any[]
  profile: any[]
  contract: any[]
  group: any[]
}

export interface ITotalCounts {
  txs: number
  profile: number
  contract: number
  group: number
}

export const emptyResults: IResults = { tx: [], profile: [], contract: [], group: [] }
export const emptyCounts: ITotalCounts = { txs: 0, profile: 0, contract: 0, group: 0 }

export const SearchBar = ({
  searchRoute,
  setParentData,
  setIsParentLoading,
}: {
  searchRoute?: any
  setParentData?: Dispatch<SetStateAction<IResults>>
  setIsParentLoading?: Dispatch<SetStateAction<boolean>>
}) => {
  const navigate = useNavigate()
  const { state } = useLocation()
  const { search: searchDelegated } = (state ?? { search: '' }) as { search: string }

  const [data, setData] = useState(emptyResults)
  const [totalCounts, setTotalCounts] = useState(emptyCounts)
  const [search, setSearch] = useState('')
  const searchThrottled = useDebounce(search, 500)
  const [isLoading, setIsLoading] = useState(false)

  const resultsPopup = useDisclosure()
  const ref = useRef(null!)
  const inputReference = useRef<HTMLInputElement>(null!)
  const location = useLocation()

  const [suggestion, setSuggestion] = useState<string>('')
  const [hasSuggestion, setHasSuggestion] = useState(false)
  const [roles, setRoles] = useState<any[]>()

  useEffect(() => {
    if (setParentData !== undefined) {
      setParentData(data)
    }
  }, [data])

  const keyboardHandler = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        if (search === suggestion) {
          navigate({ pathname: '/search', search: search })
        } else if (suggestion) {
          setSearch(suggestion)
        } else {
          navigate({ pathname: '/search', search: search })
        }
      }
    },
    [suggestion, search]
  )

  useEffect(() => {
    document.addEventListener('keydown', keyboardHandler, false)

    return () => {
      document.removeEventListener('keydown', keyboardHandler, false)
    }
  }, [keyboardHandler])

  const fetchRoles = async () =>
    await loaded(async () =>
    /* FIXME: TODO: api.candao.io deprecation */
      fetcher(`${environment.CANDAO_API}/api/profession-role`, {
        pagination: {
          page: 1,
          per_page: 999,
        },
      }).then(res => {
        setRoles(res.profession_roles)
      })
    )

  useOutsideClick({
    ref,
    handler: () => resultsPopup.onClose(),
  })

  const handleSearch = (e: any) => {
    setSearch(e.target.value)
    const newSuggestion = roles?.filter((role: any) => {
      return role.name.slice(0, e.target.value.length).toLowerCase().includes(e.target.value.toLowerCase())
    })[0]
    setSuggestion(newSuggestion?.name ? `${e.target.value}${newSuggestion?.name.slice(e.target.value.length)}` : '')
    setHasSuggestion(newSuggestion !== undefined)

    if (e.target.value.length) {
      resultsPopup.onOpen()
    }
  }

  const handleClose = () =>
    setSearch(prevState => {
      if (!prevState) {
        resultsPopup.onClose()
      }

      setData(emptyResults)
      setSuggestion('')
      return ''
    })

  const handleOpen = () => {
    if (!search.length) {
      return
    }

    resultsPopup.onOpen()
  }

  /** Accept incoming search values */
  useEffect(() => {
    if (!searchDelegated) {
      return
    }

    setSearch(searchDelegated)
    navigate('/dashboard', { state: {} })
    resultsPopup.onOpen()
  }, [searchDelegated])

  useAsyncEffect(async () => {
    if (searchThrottled.length < 2) {
      setSuggestion('')
      setData(emptyResults)
      setIsLoading(false)
      return
    }

    void loaded(
      async () => {
        const data = await fetchApi(`/search/${searchThrottled}`)
        /** Display account if not fetched and pasted in */
        if (
          isAddress(searchThrottled) &&
          !data.profile.flatMap((element: any) => element.address_?.[0]).includes(searchThrottled.toLowerCase())
        ) {
          data.profile.push({
            ...emptyProfile,
            address_: [searchThrottled.toLowerCase()],
            address: searchThrottled.toLowerCase(),
            name: 'No name',
            _created_at: new Date(),
            _updated_at: new Date(),
            artificial: true,
          })
        }
        setData(data)
        setTotalCounts(data.totalCount)
      },
      v => {
        setIsLoading(v)
        setIsParentLoading?.(v)
      }
    )
  }, [searchThrottled])

  /** Focus input */
  useEffect(() => {
    inputReference.current?.focus()
    fetchRoles()
  }, [])

  /** Display sth when about to change state */
  useEffect(() => {
    if (search.length < 2) {
      setSuggestion('')
      return
    }

    if (searchThrottled.length < 2) {
      setData(emptyResults)
    }

    setIsLoading(search !== searchThrottled)
  }, [search])

  /** Route listener */
  useEffect(() => {
    if (searchDelegated) {
      return
    }

    // todo deprecate - make this pane a popover
    handleClose()
    handleClose()
  }, [location.pathname])

  return (
    <Flex
      w="100%"
      justifyContent="center"
    >
      <Popover>
        <Flex
          borderRadius="8px"
          alignItems="center"
          bg="backgroundSecondary"
          padding="0 16px"
          border="1px solid"
          borderColor="borderPrimary"
          justifyContent="center"
          w={{ base: '90%', lg: '744px' }}
          minH="64px"
          position="relative"
          flexDir="column"
          onClick={() => inputReference.current.focus()}
          cursor="text"
        >
          <Flex
            w="100%"
            alignItems="center"
          >
            <Flex
              w="100%"
              alignItems="center"
              onClick={handleOpen}
            >
              <Image
                src="/assets/icons/zoom.svg"
                boxSize="16px"
              />
              {/** todo update loading state updating (spinner does
               * not show when data still hasnt finished loading) */}
              <Input
                ref={inputReference}
                value={search}
                color="textSecondary"
                fontSize="14px"
                placeholder="Search by address, tx hash, block or domain"
                _placeholder={{ color: 'textTertiary' }}
                border="none"
                _focus={{ boxShadow: 'none', border: 'none !important', outline: 'none !important' }}
                onChange={handleSearch}
              />
              <Text
                position="absolute"
                fontSize="14px"
                border="none"
                opacity="0.4"
                ml="32px"
                color="textSecondary"
                transform="translateY(.4px)"
              >
                {suggestion}
              </Text>
            </Flex>
          </Flex>
          {resultsPopup.isOpen && !searchRoute && (
            <SearchResults
              hasSuggestions={hasSuggestion}
              ref_={ref}
              closeHandle={handleClose}
              data={data}
              isLoading={isLoading}
              totalCounts={totalCounts}
              search={search}
            />
          )}
        </Flex>
      </Popover>
    </Flex>
  )
}
