import { Badge, Box, Flex, Grid, Image, Spinner } from '@chakra-ui/react'
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'
import { Footer, Heading, Stage } from '../UserOnboarding'
import { useDropzone } from 'react-dropzone'
import { useProfileProvider } from '../../../../../provider/Profile/profileProvider'
import { fetchApi } from '../../../../../utils/fetcher'
import { loaded } from '../../../../../utils/process'
import { buildIpfsGateway } from '../../../../../utils/parser'
import { useUserOnboardingProvider } from '../../../../../provider/UserOnboarding/userOnboardingProvider'

interface IPhotos {
  setStage: Dispatch<SetStateAction<Stage>>
}

export default function Photos({ setStage }: IPhotos) {
  const [isUploading, setIsUploading] = useState(false)

  const onboardingProvider = useUserOnboardingProvider()

  const uploadFile = (file: any) => {
    const reader = new FileReader()

    reader.onload = async () => {
      const blob = await (await fetch(reader.result as any)).blob()
      const fileParsed = new File([blob], file.name)

      const formData = new FormData()
      formData.append('file', fileParsed)

      const result = await loaded(
        async () =>
          (
            await fetchApi('/post/uploadAsset', formData, {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            })
          ).path,
        setIsUploading
      )

      onboardingProvider.setImages(prevState => [...prevState, result])
    }

    reader.readAsDataURL(file)
  }

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: acceptedFiles => {
      for (let i = 0; i < acceptedFiles.length; i++) {
        uploadFile(acceptedFiles[i])
      }
    },
  })

  const dropzoneRef = useRef(null!)

  const handlePicker = () => {
    if (dropzoneRef.current) {
      // @ts-ignore
      dropzoneRef.current.click()
    }
  }

  return (
    <>
      <Heading
        step={2}
        heading="Upload photos"
        subheading="Select and add your photos that represent you best. Statistically, users with at least 3 photos receive significantly more inquiries."
      />
      <Grid
        templateColumns="repeat(4, 1fr)"
        px="24px"
        gap="16px"
      >
        {onboardingProvider.images.map((file: any) => {
          return (
            <Flex
              bgColor="borderPrimary"
              borderRadius="8px"
              key={file}
            >
              <Image
                width="114px"
                height="114px"
                src={buildIpfsGateway(file)}
                objectFit="contain"
                borderRadius="8px"
              />
            </Flex>
          )
        })}
        <Box
          width="114px"
          height="114px"
          borderStyle="dashed"
          borderColor="#D8DAE5"
          borderWidth="1px"
          borderRadius="8px"
          display="grid"
          placeItems="center"
          cursor="pointer"
          onClick={handlePicker}
          {...getRootProps()}
        >
          <input {...getInputProps()} />
          {!isUploading ? (
            <Badge
              background="backgroundAccentLight"
              color="#3366FF"
              fontWeight="500"
              px="8px"
              py="4px"
              textTransform="none"
            >
              Add picture
            </Badge>
          ) : (
            <Spinner />
          )}
        </Box>
      </Grid>
      <Footer
        action={() => setStage(Stage.INFO)}
        goBack={() => setStage(Stage.COMPARISON)}
      />
    </>
  )
}
