import { Button, Flex, Image, Text } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { UserNft } from './UserNft'
import { useProfile } from '../../../../hooks/useProfile'
import { useProfileProvider } from '../../../../provider/Profile/profileProvider'
import useAsyncEffect from '../../../../hooks/effects/async'
import { loaded } from '../../../../utils/process'
import { fetchApi } from '../../../../utils/fetcher'
import { ChevronDownIcon } from '@chakra-ui/icons'

export interface INft {
  price: string
  metadata: {
    image: string
    name: string
  }
  name: string
  address: address
  id: string
  chain: 'BSC'
}

export const UserNfts = () => {
  const profile = useProfileProvider()
  const [nfts, setNfts] = useState<INft[]>()

  useAsyncEffect(
    async () =>
      await loaded(async () => {
        if (!profile.address) {
          return
        }

        /* cache */
        if (profile.nfts?.length || !profile.isOnboarded) {
          setNfts(profile.nfts)
          return
        }

        setNfts(await fetchApi('/profile/nfts', { address: profile.address }))
      }),
    [profile.address]
  )

  if (!nfts?.length) {
    return null
  }

  return (
    <Flex
      p={{ base: '20px', md: '16px' }}
      border="1px solid"
      borderColor="borderPrimary"
      bg="backgroundMain"
      gap="24px"
      borderX={{ base: 'none', md: '1px solid' }}
      borderLeftColor="borderPrimary !important"
      borderRightColor="borderPrimary !important"
      borderRadius={{ base: '0', md: '16px' }}
      flexDir="column"
      overflow="auto"
      w="100%"
    >
      <Flex
        align="center"
        justify="space-between"
      >
        <Flex
          gap="12px"
          align="center"
        >
          <ChevronDownIcon color="backgrounMain" />
          {/* <Image src="/assets/icons/chevron-down-big.svg" /> */}
          <Text
            fontSize="16px"
            color="backgroundAccent"
            fontWeight="600"
          >
            NFT Portfolio
          </Text>
        </Flex>
        <Button
          sx={{ display: 'flex', alignItems: 'center' }}
          variant="slave"
          isDisabled
          gap="8px"
          cursor="pointer"
        >
          <Text
            color="textBlue"
            fontSize="14px"
          >
            Show all NFTs
          </Text>
          <Image src="/assets/icons/arrowRightBlue.svg" />
        </Button>
      </Flex>
      <Flex
        gap="8px"
        overflow="auto"
        maxW="100%"
      >
        {nfts.map(nft => (
          <UserNft
            nft={nft}
            key={`${nft.address}_${nft.id}`}
          />
        ))}
      </Flex>
    </Flex>
  )
}
