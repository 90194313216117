import {
  UseDisclosureReturn,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Flex,
  Input,
  ModalFooter,
  Button,
  Text,
  Image,
  useToast,
} from '@chakra-ui/react'
import { FC, useReducer, useState } from 'react'
import { getContract } from '@wagmi/core'
import { Abi } from '../../../../contracts/abi'
import { useWalletClient } from 'wagmi'
import { _log } from '../../../../logger'
import { loaded } from '../../../../utils/process'
import { GroupCreatorStep, useGroupCreatorProvider } from '../../../../provider/GroupCreator/groupCreatorProvider'
import { IGroup } from '../../../../hooks/useGroup'
import { fetchApi } from '../../../../utils/fetcher'

type ImportTokenModalProps = {
  handleCreate: (groupCreator: Partial<IGroup>) => Promise<void>
}

export const ImportTokenModal: FC<ImportTokenModalProps> = ({ handleCreate }) => {
  //  TODO: Things to check
  // 1. Check if token is used in another group

  const [tokenAddess, setTokenAddess] = useState('')
  const [tokenSymbol, setTokenSymbol] = useState('')
  const [tokenDecimals, setTokenDecimals] = useState(0)

  const walletClient_ = useWalletClient()
  const walletClient = walletClient_.data!

  const toast = useToast()

  const [isAddressLoading, setIsAddressLoading] = useState(false)
  const [isAdresValid, setIsAdresValid] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [importButtonLoading, setImportButtonLoading] = useState(false)

  const groupCreator = useGroupCreatorProvider()

  const handleCheckAddress = async (addr: string) => {
    if (addr.length === 42) {
      await loaded(
        async () => {
          const contract = getContract({
            address: addr as address,
            abi: Abi.ERC20,
            walletClient,
          })
          const decimals = await contract.read.decimals()
          const symbol = await contract.read.symbol()
          setTokenSymbol(symbol as string)
          setTokenDecimals(decimals as number)

          await loaded(async () => {
            const result = await fetchApi(`/group/${addr}`)

            const message = 'Group already exists'
            setErrorMessage(message)
            throw new Error(message)
          })

          setErrorMessage('')
          setIsAdresValid(true)
        },
        e => {
          setIsAddressLoading(e)
          setImportButtonLoading(e)
        },
        () => {
          setErrorMessage('The token does not exist on the currently connected network.')
          setIsAdresValid(false)
        }
      )
    } else {
      setIsAdresValid(false)
    }
  }

  const handleImport = async () => {
    // Funkcja handle create jest tutaj gdyby trzeba było coś jeszcze zwalidować itp..
    await loaded(async () => {
      await handleCreate({
        address: tokenAddess as address,
        owner: walletClient.account.address,
      })
    }, setImportButtonLoading)
  }

  // TODO: Check if group with token already exists

  return (
    <Modal
      size="xl"
      isOpen={groupCreator.isOpen}
      onClose={groupCreator.onClose}
    >
      <ModalContent>
        <ModalHeader
          borderBottom="1px solid borderPrimary"
          fontSize="18px"
        >
          Import
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody
          fontSize="14px"
          p="0"
          m="0"
        >
          <Flex
            p="24px"
            flexDirection="column"
            gap="24px"
          >
            <Text color="backgroundAccent">
              Import an existing token by selecting its symbol and address to merge it seamlessly with your group.
            </Text>
            <Flex
              gap="8px"
              flexDirection="column"
              alignItems="flex-start"
            >
              <Text
                color="backgroundAccent"
                fontWeight="500"
              >
                Token Address
              </Text>
              <Input
                isInvalid={tokenAddess.length === 42 ? !isAdresValid : false}
                placeholder="0x..."
                value={tokenAddess}
                onChange={e => {
                  setTokenAddess(e.target.value)
                  handleCheckAddress(e.target.value)
                }}
              />
              {errorMessage.length > 0 && <Text color="redPrimary">{errorMessage}</Text>}
            </Flex>
            <Flex
              gap="8px"
              flexDirection="column"
              alignItems="flex-start"
            >
              <Text
                color="backgroundAccent"
                fontWeight="500"
              >
                Token symbol
              </Text>
              <Input
                isDisabled={isAddressLoading || isAdresValid}
                placeholder="ETH"
                value={tokenSymbol}
                onChange={e => setTokenSymbol(e.target.value)}
              />
            </Flex>
            <Flex
              gap="8px"
              flexDirection="column"
              alignItems="flex-start"
            >
              <Text
                color="backgroundAccent"
                fontWeight="500"
              >
                Token decimal
              </Text>
              <Input
                isDisabled={isAddressLoading || isAdresValid}
                placeholder="18"
                type="number"
                value={tokenDecimals || ''}
                onChange={e => setTokenDecimals(Number(e.target.value))}
              />
            </Flex>
          </Flex>
        </ModalBody>

        <ModalFooter borderTop="1px solid borderPrimary">
          <Button
            colorScheme="blue"
            mr={3}
            onClick={() => groupCreator.setStep(GroupCreatorStep.CreateToken)}
          >
            Back
          </Button>
          <Button
            background="backgroundAccent"
            textColor="#FAFBFF"
            onClick={handleImport}
            isDisabled={!isAdresValid || importButtonLoading}
            isLoading={importButtonLoading}
          >
            Import
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
