import React, { useEffect, useState } from 'react'
import { Button, Flex, Text, useToast, Spinner, InputGroup, InputLeftElement, Input, Image } from '@chakra-ui/react'
import { useLocation } from 'react-router-dom'
import useAsyncEffect from '../hooks/effects/async'
import { fetchApi } from '../utils/fetcher'
import { loaded } from '../utils/process'
import { useContractsProvider } from '../provider/Contracts/contractsProvider'
import { waitForTransaction } from '@wagmi/core'
import { useEthereumProvider } from '../provider/Ethereum/ethereumProvider'
import { IGroup } from '../hooks/useGroup'
import { CreateGroup } from '../components/pages/Groups/CreateGroupFlow/CreateGroup'
import { GroupCreatorProvider } from '../provider/GroupCreator/groupCreatorProvider'
import { GroupsMarketCap } from '../components/pages/Groups/GroupsMarketCap'
import { usePagination } from '@ajna/pagination'
import { useDebounce } from '../hooks/useDebounce'
import { _log } from '../logger'

export const Groups = () => {
  const [groups, setGroups] = React.useState<IGroup[]>()
  const [isGroupCreationLoading, setIsGroupCreationLoading] = React.useState(false)
  const [isCreatorVisible, setIsCreatorVisible] = useState(false)
  const [numOfPages, setNumOfPages] = useState(1)
  const [filterInput, setFilterInput] = useState('')
  const [isSearching, setIsSearching] = useState(false)

  const outerLimit = 2
  const innerLimit = 2
  const filter = useDebounce(filterInput, 500)

  const toast = useToast()
  const contracts = useContractsProvider()
  const wallet = useEthereumProvider()
  const { state } = useLocation()
  const { pages, pagesCount, offset, currentPage, setCurrentPage, setIsDisabled, isDisabled, pageSize, setPageSize } =
    usePagination({
      total: numOfPages * 100,
      limits: {
        outer: outerLimit,
        inner: innerLimit,
      },
      initialState: {
        pageSize: 100,
        isDisabled: false,
        currentPage: 1,
      },
    })

  const pushGroup = (newGroup: IGroup) => setGroups(prev => [...(prev ?? []), newGroup])

  const handleCreate = async (groupCreator: Partial<IGroup>) => {
    if (!wallet.account) {
      toast({ status: 'error', title: 'Connect wallet first' })
      return
    }
    await loaded(
      async () => {
        const calldata = [
          groupCreator.address,
          [0, 1, 2],
          [
            groupCreator.metadata?.name || '',
            groupCreator.metadata?.description || '',
            groupCreator.metadata?.image || '',
          ],
        ]
        await loaded(
          async () => {
            await contracts.groups?.estimateGas.setMultipleGroupData(calldata)
          },
          undefined,
          _ => {
            toast({ status: 'error', title: 'This address has already been imported”' })
            throw new Error('Token not ERC20')
          }
        )
        const hash = await contracts.groups?.write.setMultipleGroupData(calldata)
        await waitForTransaction({ hash })

        toast({ title: 'Group created!' })
        setIsCreatorVisible(false)

        const newGroup: IGroup = {
          address: groupCreator.address || '0x0',

          symbol: groupCreator.symbol || '0x0',
          owner: groupCreator.owner || '0x0',

          metadata: { ...groupCreator.metadata },
        }

        pushGroup(newGroup)
      },
      setIsGroupCreationLoading,
      error => {
        if (!error.shortMessage) {
          return
        }
        toast({ status: 'error', title: error.shortMessage })
      }
    )
  }

  /* Upon search trigger loading */
  useEffect(() => {
    if (filterInput === filter) {
      return
    }

    setIsSearching(true)
  }, [filterInput, filter])

  /* Handling page change */
  useAsyncEffect(
    async () =>
      loaded(async () => {
        const data = (await fetchApi('/group', { currentPage, filter })).data

        setGroups(data)
        setNumOfPages(Math.ceil(data.length / pageSize))

        setIsSearching(false)
      }),
    [currentPage, filter]
  )

  /* Handle group creation intent */
  useEffect(() => {
    if (state?.openCreator) {
      setIsCreatorVisible(true)
    }
  }, [])

  return (
    <>
      <GroupCreatorProvider
        onClose={() => setIsCreatorVisible(false)}
        isOpen={isCreatorVisible}
      >
        <CreateGroup handleCreate={handleCreate} />
      </GroupCreatorProvider>
      <Flex
        bg="backgroundSecondary"
        justify="center"
        w="100%"
        overflow="hidden"
        flexDirection="column"
        sx={{
          py: '36px',
          gap: '16px',
        }}
      >
        <Flex sx={{ alignItems: 'center', justifyContent: 'space-between', mb: '8px' }}>
          <Text sx={{ fontSize: '24px', fontWeight: '600' }}>Group MarketCap</Text>
          <Button
            color="white"
            bg="textBlue"
            onClick={() => setIsCreatorVisible(true)}
            isLoading={isGroupCreationLoading}
            border="none"
            sx={{
              fontWeight: 500
            }}
          >
            Create new group
          </Button>
        </Flex>
        <InputGroup>
          <InputLeftElement>
            <Image src="/assets/icons/search.svg" />
          </InputLeftElement>
          <Input
            placeholder="Search group by name, address, tx hash, block or domain"
            _focus={{ border: '1px solid', borderColor: 'borderPrimary !important' }}
            sx={{ borderRadius: '8px' }}
            value={filterInput}
            onChange={event => setFilterInput(event.target.value)}
          />
        </InputGroup>
        <GroupsMarketCap
          data={groups}
          numOfPages={numOfPages}
          currentPage={currentPage}
          pages={pages}
          handlePageChange={page => setCurrentPage(page)}
          isLoading={isSearching}
        />
      </Flex>
    </>
  )
}

export default Error
