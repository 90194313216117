import { Flex, Text } from '@chakra-ui/react'
import React, { SetStateAction, useEffect } from 'react'
import { fetcher } from '../../../../../utils/fetcher'
import { Select, Type } from '../../../../shared/select/Select'
import { loaded } from '../../../../../utils/process'
import { fetchRoles } from '../../../../shared/select/utils'
import { RolesInterestsPicker } from '../../../../shared/roles&interestsPicker/rolesInterestsPicker'

interface IProps {
  roles: string[]
  setRoles: React.Dispatch<SetStateAction<string[]>>
}

export const RolesTab = ({ roles, setRoles }: IProps) => {
  return (
    <Flex
      p="32px 24px"
      flexDir="column"
      height="auto"
    >
      <Flex
        flexDir="column"
        gap="2px"
      >
        <Text
          fontWeight="500"
          color="backgroundAccent"
          fontSize="14px"
          mb="12px"
        >
          Edit your roles
        </Text>
      </Flex>
      <RolesInterestsPicker
        type={Type.ROLES}
        items={roles}
        setItems={setRoles}
        fetch={fetchRoles}
      />
    </Flex>
  )
}
