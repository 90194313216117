import { Button, Flex, Tooltip, Text, useToast } from '@chakra-ui/react'
import { FC, useMemo, useState } from 'react'
import { EFeed, EGroupTab, useTabProvider } from '../../../provider/Group/tabProvider'
import { _log } from '../../../logger'
import { loaded } from '../../../utils/process'
import { useGroupProvider } from '../../../provider/Group/groupProvider'
import { GroupCreatorStep } from '../../../provider/GroupCreator/groupCreatorProvider'
import { fetchApi } from '../../../utils/fetcher'

type TGroupTabPickerProps = {
  isVisible: boolean
}

export const GroupFeedPicker: FC<TGroupTabPickerProps> = ({ isVisible }) => {
  const [customFeeds, setCustomFeeds] = useState<string[]>([])
  const [isAddingFeed, setIsAddingFeed] = useState(false)

  const toast = useToast()

  const { feed, setFeed, currentTab } = useTabProvider()
  const group = useGroupProvider()

  const roles = useMemo(() => {
    const rolesMap = new Map<string, number>()
    group.members?.forEach(member => {
      member.roles.forEach(role => {
        const v = rolesMap.get(role) ?? 0
        rolesMap.set(role, v + 1)
      })
    })
    return rolesMap
  }, [group.members])

  const handleAddTeam = async () =>
    await loaded(
      async () => {
        const newFeed = prompt('[New feed]: Provide name')

        if (!newFeed) {
          throw new Error('Feed name cannot be empty')
        }

        const newFeeds = Array.from(new Set([...(group.feeds || []), newFeed]))

        await fetchApi(`/group/setFeeds`, { group: group.address, feeds: newFeeds })
        group.setData(previous => ({ ...previous, feeds: newFeeds }))
      },
      setIsAddingFeed,
      error => toast({ status: 'error', title: 'Failed to create feed', description: error?.message })
    )

  return (
    <Flex
      sx={{
        opacity: isVisible ? 1 : 0,
        pointerEvents: !isVisible ? 'none' : 'auto',
        transition: 'opacity .1s ease-in-out',
        w: { base: '100%', sm: '168px' },
        flexDirection: 'column',
        gridGap: '2px',
        bgColor: 'backgroundMain',
        p: '16px',
        alignSelf: 'flex-start',
        borderRadius: '16px',
        border: '1px solid',
        borderColor: 'borderPrimary',
      }}
    >
      <Text
        sx={{
          fontSize: '16px',
          fontWeight: '600',
          pb: '12px',
        }}
      >
        Team feeds
      </Text>
      {Array.from(
        new Set([
          ...(Object.keys(EFeed) as (keyof typeof EFeed)[]),
          ...(group.feeds || []),
          ...Array.from(roles).map(role => role[0]),
        ])
      ).map(tab => {
        return (
          <Tooltip key={tab}>
            <Button
              display="flex"
              __css={{}}
              _disabled={{ filter: 'grayscale(1)', opacity: '.5', cursor: 'not-allowed' }}
              key={tab}
              _hover={{
                background: 'backgroundAccentLight',
              }}
              bgColor={feed === tab ? 'backgroundAccentLight' : 'transparent'}
              color={feed === tab ? 'textBlue' : 'textSecondary'}
              sx={{
                fontSize: '14px',
                alignItems: 'center',
                justifyContent: 'flex-start',
                textAlign: 'left',
                p: '6px 8px',
                borderRadius: '4px',
                cursor: 'pointer',
                w: { xl: '140px', sm: 'auto' },
                gap: '24px',
              }}
              onClick={() => setFeed(tab as EFeed)}
            >
              {tab}
            </Button>
          </Tooltip>
        )
      })}
      {group.isOwner && (
        <Button
          display="flex"
          __css={{}}
          sx={{
            _hover: {
              background: 'backgroundAccentLight',
            },
            color: 'textBlue',
            fontWeight: 500,
            fontSize: '14px',
            alignItems: 'center',
            justifyContent: 'space-between',
            p: '6px 8px',
            borderRadius: '4px',
            cursor: 'pointer',
            w: { xl: '140px', sm: 'auto' },
          }}
          onClick={handleAddTeam}
        >
          + Add team
        </Button>
      )}
    </Flex>
  )
}
