import {
  Flex,
  Button,
  Box,
  Image,
  Text,
  PopoverContent,
  PopoverTrigger,
  Popover,
  useDisclosure,
  Textarea,
  useToast,
  Tooltip,
  Spinner,
} from '@chakra-ui/react'
import { useGroupProvider } from '../../../provider/Group/groupProvider'
import useAsyncEffect from '../../../hooks/effects/async'
import { loaded } from '../../../utils/process'
import { IProfile, emptyProfile } from '../../../provider/Profile/profileProvider'
import { useMemo, useState } from 'react'
import { fetchApi } from '../../../utils/fetcher'
import { buildIpfsGateway, trimAddress } from '../../../utils/parser'
import { Link } from 'react-router-dom'
import { ListOption } from '../Home/MiddlePanel/roles/tabs/discussions/PostOptions'
import { EditGroupModal } from './EditGroupModal'
import { useAccount } from 'wagmi'
import { useEthereumProvider } from '../../../provider/Ethereum/ethereumProvider'
import { useContractsProvider } from '../../../provider/Contracts/contractsProvider'
import { JoinAsModal } from './JoinAsModal'
import { waitForTransaction } from '@wagmi/core'
import { InviteModal } from './inviteModal/InviteModal'
import { includesInsensitive } from '../../../utils/ts'
import SpinnerText from '../../shared/tags/SpinnerText'
import moment from 'moment'

export const GroupHeader = () => {
  const [descriptionInput, setDescriptionInput] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)

  const group = useGroupProvider()

  const [author, setAuthor] = useState(emptyProfile)
  const wallet = useEthereumProvider()

  const contracts = useContractsProvider()

  useAsyncEffect(
    async () =>
      await loaded(async () => {
        const authorProfile: IProfile = await fetchApi(`/profile/${group.owner}`)

        setAuthor(authorProfile)
      }),
    [group]
  )

  const editGroupDisclosure = useDisclosure()
  const joinAsDisclosure = useDisclosure()
  const inviteDisclosure = useDisclosure()
  const toast = useToast()

  const handleDescriptionChange = (e: any) => setDescriptionInput(e.target.value)

  const handleDescriptionSubmit = async () => {
    await loaded(
      async () => {
        const calldata = [group.address, [0, 1, 2], [group.metadata.name, descriptionInput, group.metadata.image]]
        await loaded(
          async () => {
            await contracts.groups?.estimateGas.setMultipleGroupData(calldata)
          },
          undefined,
          _ => {
            toast({ status: 'error', title: 'Error while uploading data' })
            throw new Error('Uploading error')
          }
        )
        const hash = await contracts.groups?.write.setMultipleGroupData(calldata)
        await waitForTransaction({ hash })
        toast({ title: 'Group edited!' })
      },
      setIsSubmitting,
      error => {
        if (!error.shortMessage) {
          return
        }
        toast({ status: 'error', title: error.shortMessage })
      }
    )
  }

  const hasUserJoined = useMemo(
    () => includesInsensitive(group?.members?.map(m => m.address) ?? [], wallet.account ?? ''),
    [wallet, group.address]
  )

  return (
    <>
      <JoinAsModal disclosure={joinAsDisclosure} />
      <EditGroupModal disclosure={editGroupDisclosure} />
      <InviteModal disclosure={inviteDisclosure} />
      <Flex
        w="100%"
        flexDir="column"
        color="textPrimary"
        padding="0"
      >
        <Flex
          position="relative"
          borderRadius={{ base: '0', md: '16px 16px 0 0' }}
          w="100%"
          h="100px"
          sx={{
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center',
          }}
          borderColor="borderPrimary"
          background={
            !group.metadata.background ? 'bluePlaceholder' : `url(${buildIpfsGateway(group.metadata.background)})`
          }
        />

        <Flex
          bgColor="backgroundMain"
          flexDirection="column"
          border="1px solid"
          borderColor="borderPrimary"
          borderTop="none"
          w="100%"
          p={{ base: '16px', md: '16px' }}
          position="relative"
          justify={{ base: 'flex-start', lg: 'space-between' }}
          direction={{ base: 'column', lg: 'row' }}
          sx={{
            borderBottomRadius: '16px',
          }}
        >
          <Image
            src={buildIpfsGateway(group.metadata.image)}
            fallback={
              <Image
                src="/assets/icons/bnbIcon.svg"
                borderRadius="50%"
                sx={{ boxSize: '37px', p: '8px' }}
                position="absolute"
                top="-28px"
                bg="backgroundMain"
              />
            }
            borderRadius="50%"
            width="37px"
            height="37px"
            position="absolute"
            objectFit="cover"
            top="-28px"
            bg="backgroundMain"
          />
          <Flex
            width="100%"
            justify={{ base: 'flex-start', lg: 'space-between' }}
            direction={{ base: 'column', lg: 'row' }}
            sx={{
              gap: '8px',
            }}
          >
            <Flex
              width="100%"
              direction="column"
              gap="3px"
            >
              <Text
                color="backgroundAccent"
                fontSize="18px"
                fontWeight="600"
                sx={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {group.metadata.name}
              </Text>
              <Flex
                flexDir="column"
                justifyContent="flex-start"
                gap="10px"
              >
                <Flex gap="4px">
                  <Text
                    color="textSecondary"
                    fontSize="12px"
                  >
                    Manager:{' '}
                    <Link to={`/profile/${group.owner}`}>
                      <Text
                        as="span"
                        color="#36F"
                      >
                        {author.name ?? trimAddress(group?.owner)}
                      </Text>
                    </Link>
                  </Text>
                </Flex>

                <Flex
                  gap="4px"
                  alignItems="center"
                >
                  <Image
                    src="/assets/icons/calendar.svg"
                    width="16px"
                    height="16px"
                  />
                  <Text
                    color="textSecondary"
                    fontSize="12px"
                  >
                    Created:{' '}
                    <Text
                      as="span"
                      color="backgroundAccent"
                    >
                      {group._created_at ? (
                        moment(group?._created_at).format('MMM Do YYYY').toString()
                      ) : (
                        <SpinnerText />
                      )}
                    </Text>
                  </Text>
                </Flex>

                <Flex
                  gap="4px"
                  alignItems="center"
                >
                  <Image
                    src="/assets/icons/data-users.svg"
                    width="16px"
                    height="16px"
                  />
                  <Text
                    color="textSecondary"
                    fontSize="12px"
                  >
                    Members:{' '}
                    <Text
                      as="span"
                      color="backgroundAccent"
                      display="inline-block"
                    >
                      {group.members?.length || 0}
                    </Text>
                  </Text>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
          <Box
            mt="23px"
            position="relative"
            fontSize="14px"
            color="textQuaternary"
            fontWeight="500"
            whiteSpace="pre-wrap"
          >
            {group.metadata.description ? (
              group.metadata.description?.split(' ')?.map((word: string) =>
                word.startsWith('#') ? (
                  <Text
                    color="textBlue"
                    as="span"
                    //whiteSpace="pre-wrap"
                    display="inline-block"
                  >
                    {word}{' '}
                  </Text>
                ) : (
                  word + ' '
                )
              )
            ) : (
              <Flex
                w={{ base: '100%', md: '60%' }}
                overflow="hidden"
              >
                <Textarea
                  placeholder="Click here and write a description about your group"
                  border="none"
                  _placeholder={{ fontSize: '14px', color: '#8F95B2' }}
                  _focus={{ outline: 'none !important', border: 'none !important' }}
                  color="backgroundAccent"
                  p="0"
                  value={descriptionInput}
                  onChange={handleDescriptionChange}
                  resize="none"
                />

                {descriptionInput.length > 0 &&
                  (isSubmitting ? (
                    <Spinner
                      boxSize="18px"
                      right="0px"
                      position="absolute"
                    />
                  ) : (
                    <Image
                      cursor="pointer"
                      right="0px"
                      position="absolute"
                      onClick={handleDescriptionSubmit}
                      src="/assets/icons/check.svg"
                      boxSize="18px"
                    />
                  ))}
              </Flex>
            )}
          </Box>
        </Flex>
      </Flex>
    </>
  )
}

const Eclipse = () => (
  <Box
    width="2px"
    height="2px"
    borderRadius="full"
    backgroundColor="#8F95B2"
    display="block"
  />
)
