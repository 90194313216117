import { FC, useState } from 'react'
import { IPostSchema, emptyPostSchema } from '../../pages/Home/MiddlePanel/deafult/Default'
import {
  Center,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  UseDisclosureReturn,
} from '@chakra-ui/react'
import SpinnerText from '../tags/SpinnerText'
import { Post } from '../../pages/Home/MiddlePanel/roles/tabs/discussions/Post/Post'
import useAsyncEffect from '../../../hooks/effects/async'
import { fetchApi } from '../../../utils/fetcher'
import { loaded } from '../../../utils/process'
import { useNavigate } from 'react-router-dom'
import { useGroupProvider } from '../../../provider/Group/groupProvider'
import { useTabProvider } from '../../../provider/Group/tabProvider'

type TPostModalProps = {
  ipfs: IPostSchema['ipfs']
  disclosure: UseDisclosureReturn
}

export const PostModal: FC<TPostModalProps> = ({ ipfs, disclosure }) => {
  const { isOpen, onClose } = disclosure
  const [post, setPost] = useState(emptyPostSchema)
  const [isPostLoading, setIsPostLoading] = useState(true)
  const navigate = useNavigate()
  const group = useGroupProvider()

  useAsyncEffect(async () => {
    await loaded(async () => {
      const res = await fetchApi('/post', [ipfs])
      if (res.length > 0) {
        setPost(res[0])
      }
    }, setIsPostLoading)
  }, [ipfs])

  const { currentTab } = useTabProvider()

  const customQuestAction = (action: 'join' | 'invite') => {
    disclosure.onClose()
    navigate(`/group/${post.group}`, { state: { ipfs: post.ipfs, action } })
  }

  return (
    <Modal
      size="xl"
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader sx={{ borderBottom: '1px solid borderPrimary' }}>
          {post.quest?.name ?? <SpinnerText />}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody sx={{ p: '0px' }}>
          {isPostLoading ? (
            <Center sx={{ width: '100%', height: '200px' }}>
              <Spinner />
            </Center>
          ) : (
            <Post
              data={post}
              isModal
              customQuestAction={customQuestAction}
            />
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
