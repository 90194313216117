import {
  Button,
  Divider,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
  UseDisclosureReturn,
  useToast,
} from '@chakra-ui/react'
import { useState } from 'react'
import { fetchApi } from '../../../../../../../../../utils/fetcher'
import { loaded } from '../../../../../../../../../utils/process'

export const AppealModal = ({
  disclosure,
  parentDisclosure,
  postId,
}: {
  disclosure: UseDisclosureReturn
  parentDisclosure: UseDisclosureReturn
  postId: string
}) => {
  const [appealContent, setAppealContent] = useState('')
  const [isPosting, setIsPosting] = useState(false)

  const toast = useToast()

  const handleContentChange = (e: any) => {
    setAppealContent(e.target.value)
  }

  const handleAppeal = async () => {
    if (appealContent.length < 5) return
    await loaded(
      async () => {
        await fetchApi('/settings/appeal', { id: postId, content: appealContent })

        disclosure.onClose()
        parentDisclosure.onClose()
        toast({ title: 'Appeal posted!', duration: 3000 })
      },
      setIsPosting,
      () => null
    )
  }

  return (
    <Modal
      isOpen={disclosure.isOpen}
      onClose={() => {
        disclosure.onClose()
        parentDisclosure.onOpen()
      }}
      size="xl"
    >
      <ModalOverlay sx={{ bg: 'rgba(0, 0, 0, 0.15)' }} />
      <ModalContent
        sx={{
          bgColor: 'backgroundMain',
          boxShadow: '0px 12px 40px rgba(16, 24, 64, 0.24)',
          borderRadius: '16px',
          p: '0',
          mx: '10px',
          marginBottom: { base: '10px', md: '64px' },
        }}
      >
        <ModalHeader>
          <Text sx={{ color: 'textQuaternary', fontSize: '14px', fontWeight: '500' }}>Appeal</Text>
        </ModalHeader>
        <ModalCloseButton sx={{ mt: '4px' }} />
        <Divider orientation="horizontal" />
        <ModalBody sx={{ display: 'flex', flexDirection: 'column', gap: '8px', py: '24px' }}>
          <Text sx={{ fontSize: '14px', color: 'textQuaternary' }}>
            Compose an appeal to request that moderators review the post again.
          </Text>
          <Textarea
            placeholder="Your message"
            resize="none"
            value={appealContent}
            onChange={handleContentChange}
          />
          <Button
            bg="textBlue"
            color="backgroundMain"
            mt="8px"
            onClick={handleAppeal}
            isLoading={isPosting}
          >
            Send
          </Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
