import { Box, Flex, Text } from '@chakra-ui/react'
import { CardContainer } from '../../../shared/containers/CardContainer'
import { useState } from 'react'
import useAsyncEffect from '../../../../hooks/effects/async'
import { loaded } from '../../../../utils/process'
import { fetchApi } from '../../../../utils/fetcher'
import moment from 'moment'
import { Link } from 'react-router-dom'

export interface ITransaction {
  hash: string
  timeInSeconds: number
  recipient: string
  from: string
  type: string
}

export const LatestTransactions = () => {
  const [txs, setTxs] = useState<any[]>()

  useAsyncEffect(async () => {
    void loaded(async () => {
      try {
        const data = await fetchApi('/tx/', {})

        setTxs(data)
      } catch {
        setTxs([])
      }
    })
  }, [])

  return (
    <CardContainer>
      <Flex
        flexWrap="wrap"
        flexDir="column"
        gap="16px"
      >
        {txs &&
          txs.length &&
          txs?.slice(0, 5).map((tx, index) => (
            <Flex
              key={`${tx.hash}_${index}`}
              flexWrap="wrap"
              textColor="textSecondary"
              justifyContent="space-between"
              alignItems="center"
              fontSize="14px"
            >
              <Flex
                alignItems="center"
                gridGap="8px"
              >
                <Box
                  display="flex"
                  borderRadius="8px"
                  alignItems="center"
                  justifyContent="center"
                  bg="grayPrimary"
                  w="40px"
                  h="40px"
                >
                  <Box
                    borderRadius="50%"
                    w="12px"
                    h="12px"
                    border="1.5px solid"
                    borderColor="#C1C4D6"
                  />
                </Box>
                <Flex
                  flexWrap="wrap"
                  flexDir="column"
                  gap="4px"
                  justify="center"
                >
                  <Link to={`/tx/${tx.hash}`}>{tx.hash?.slice(0, 10)}···</Link>
                  <Box fontSize="12px">{moment.unix(tx.blockTimestamp).fromNow()}</Box>
                </Flex>
              </Flex>
              <Flex
                flexWrap="wrap"
                align="flex-start"
                flexDir="column"
                gap="4px"
                fontSize="14px"
              >
                <Box
                  display="flex"
                  gridGap="4px"
                  pt={{ base: '12px', md: '0px' }}
                >
                  <Text
                    w="34px"
                    fontSize="14px"
                  >
                    From{' '}
                  </Text>
                  <Link to={`/profile/${tx.sender}`}>
                    {tx.sender?.slice(0, 10)}···{tx.sender?.slice(-10)}
                  </Link>
                </Box>
                <Box
                  display="flex"
                  gridGap="4px"
                >
                  <Text w="34px">To </Text>
                  <Link to={`/profile/${tx.target}`}>
                    {tx.target?.slice(0, 10)}...
                    {tx.target?.slice(-10)}
                  </Link>
                </Box>
              </Flex>
              <Flex
                flexWrap="wrap"
                justify="flex-end"
                flexDirection="column"
                gridGap="4px"
              >
                <Flex
                  color="textSecondary"
                  justify="flex-end"
                  fontSize="12px"
                  textAlign="start"
                >
                  Time
                </Flex>
                <Box
                  padding="2px 6px"
                  borderRadius="4px"
                  fontSize="10px"
                  lineHeight="12px"
                  bg="#EDEFF5"
                  color="textQuaternary"
                >
                  Txn
                </Box>
              </Flex>
            </Flex>
          ))}
      </Flex>
    </CardContainer>
  )
}
