import { useAccountProfileProvider } from '../../../../provider/AccountProfile/context'
import { fetchApi } from '../../../../utils/fetcher'
import { loaded } from '../../../../utils/process'
import { Dispatch, SetStateAction, useCallback, useState } from 'react'
import { useToast } from '@chakra-ui/react'
import { getAuthHeaders, getCandaoJwtAuthKey } from '../../../../utils/auth'
import { AddressZero } from '../../../../utils/ethereum'
import { useContractsProvider } from '../../../../provider/Contracts/contractsProvider'
import { getContract } from '@wagmi/core'
import { Abi } from '../../../../contracts/abi'
import { useWalletClient } from 'wagmi'
import { readContract, waitForTransaction } from '@wagmi/core'
import { trimAddress } from '../../../../utils/parser'
import { Address } from '../../../../contracts/address'
import { IPostSchema, PostType } from '../../../pages/Home/MiddlePanel/deafult/Default'
import { isProduction } from '../../../../utils/environment'
import { _log } from '../../../../logger'
import { ContractAlert } from '../../../global/wrapper/alerts/ContractAlert'
import { formatUnits, parseUnits } from 'viem'
import { redirect } from '../../../../utils/redirector'
import { Quest } from '../../../pages/Home/MiddlePanel/roles/tabs/discussions/Post/Post'
import { AxiosError } from 'axios'

interface IUsePost {
  post: (content: IPostContent) => Promise<string>
  isPosting: boolean
  content: IPostContent
  setContent: Dispatch<SetStateAction<IPostContent>>
  isAssetLoading: boolean
  uploadAssets: (files: any) => Promise<void>
  upgrade: (ipfs: IPostSchema['ipfs'], quest: Quest) => Promise<void>
}

interface IPostContent {
  text: string
  type: PostType
  /* shortened post-profile-referral URI, e.g.:
  `post/Qme7ehrZEyvNe7B4Wsvt9M66HNFXWxEBCHptMJk5kw5ozG-5e6c3a12-c7ef-
  47f0-a85b-0773ee393c2f?referral=rHtd2ChO5U` */
  shortLink?: string

  images: string[]
  target: {
    roles: string[]
    interests: string[]
  }
  thread: string
  sharing: string
  quest: {
    id: string
    name: string
    roles: string[]
    slots: number
  }
  transfer?: {
    token: address
    amount: number
    recipient: address
  }
  reward: {
    tokens: {
      address: string
      amount: number
      usd: number
    }[]
    usd: number
  }
  group?: address
}

interface IPostFile<T = IPostContent> {
  features: ['candao']
  author: address
  candao: T
}

const emptyPostContent: IPostContent = {
  images: [],
  text: '',
  type: PostType.Post,
  thread: '',
  sharing: '',
  target: {
    roles: [],
    interests: [],
  },
  quest: {
    id: '',
    name: '',
    roles: [],
    slots: 0,
  },
  reward: {
    tokens: [],
    usd: 0,
  },
}

const emptyPostFile: IPostFile = {
  features: ['candao'],
  author: AddressZero,
  candao: emptyPostContent,
}

export const usePost = (): IUsePost => {
  const [isPosting, setIsPosting] = useState(false)
  const [content, setContent] = useState(emptyPostContent)
  const [isAssetLoading, setIsAssetLoading] = useState(false)
  const contracts = useContractsProvider()

  const accountProfile = useAccountProfileProvider()
  const toast = useToast()
  const walletClient_ = useWalletClient()
  const walletClient = walletClient_.data!

  const post = async (content: IPostContent): Promise<string> =>
    await loaded(
      async () => {
        const timestamp = Date.now()
        let txHash: address | undefined = undefined

        if ([PostType.Quest, PostType.Task, PostType.Job].includes(content.type)) {
          if (content.reward.tokens.length == 0) {
            throw new Error('Quest post must contain rewards')
          }
          const questContractAddress = Address.QUEST
          for (const token of content.reward.tokens) {
            const allowance = await readContract({
              abi: Abi.ERC20,
              address: token.address as address,
              functionName: 'allowance',
              args: [accountProfile.address, questContractAddress as string],
            })
            if (Number(allowance) < Number(token.amount * 10 ** 18)) {
              const contract = getContract({
                abi: Abi.ERC20,
                address: token.address as address,
                walletClient,
              })
              try {
                const tx = await contract.write?.approve([
                  questContractAddress,
                  Number(token.amount) * Number(10 ** 18),
                ])

                await waitForTransaction({ hash: tx })
                toast({ title: `Approved, tx: ${trimAddress(tx)}`, status: 'info' })
              } catch (e) {
                toast({ title: `Unable to get allowance`, status: 'error' })
                return
              }
            }
          }
          try {
            const tx = await contracts.quest?.write.startQuest([
              [...content.reward.tokens.map(t => t.address.toLowerCase())],
              [...content.reward.tokens.map(t => t.amount * 10 ** 18)],
              timestamp,
            ])
            txHash = tx

            toast({ title: `Executed: ${trimAddress(tx)}`, status: 'info' })
          } catch (e) {
            toast({ title: `Unable to call contract`, status: 'error' })
            return
          }
        }

        if (content.type === PostType.Transfer) {
          const decimals = (await readContract({
            abi: Abi.ERC20,
            address: content.transfer?.token as address,
            functionName: 'decimals',
          })) as number

          const value = parseUnits(`${content.transfer?.amount ?? 0}`, decimals)

          const allowance = BigInt(
            (await readContract({
              abi: Abi.ERC20,
              address: content.transfer?.token as address,
              functionName: 'allowance',
              args: [accountProfile.address, Address.TRANSFER],
            })) as number
          )

          if (BigInt(allowance) < BigInt(value)) {
            const contract = getContract({
              abi: Abi.ERC20,
              address: content.transfer?.token as address,
              walletClient,
            })

            await loaded(async () => {
              const tx = await contract.write?.approve([Address.TRANSFER, value.toString()])

              await waitForTransaction({ hash: tx })
              _log(`Approved token transferred for ${formatUnits(value, decimals)} units`)
            })
          }

          const hash = await contracts.transfer?.write.candaoTransfer([
            content.transfer?.token,
            content.transfer?.recipient,
            value,
            content.text || '',
          ])

          toast({
            render: () => (
              <ContractAlert
                content="Transfer complete"
                class_="CandaoTransfer"
                action={{
                  call: () => redirect(`https://${isProduction() ? '' : 'testnet.'}bscscan.com/tx/${hash}`),
                  description: 'See transaction',
                }}
              />
            ),
          })
          toast({ status: 'loading', title: `Synchronising post…` })
        }

        /* Fetch user access code if not available. */
        void (async () =>
          await loaded(async () => {
            if (accountProfile.accessCode) {
              return
            }

            const storageKey = localStorage.getItem(getCandaoJwtAuthKey(accountProfile.address as address))

            /* FIXME: TODO: api.candao.io deprecation */
            // const accessCodeRaw = await fetcherGet(`${environment.CANDAO_API}/api/access-code`, {
            //   headers: {
            //     Authorization: `Bearer ${storageKey}`,
            //   },
            // })
            // const accessCode = accessCodeRaw?.access_codes?.[0].code

            /* FIXME: TODO: api.candao.io deprecation */
            await loaded(
              async () => await fetchApi('/profile/access_code', { accessCode: 'api.candao.io-deprecation-token' })
            )
          }))()

        const res = await fetchApi('/post/upload', {
          author: accountProfile.address /* todo anonymous */,
          txHash,
          ...content,
        })
        return res.path
      },
      setIsPosting,
      error => {
        if (toast.isActive('error_post')) {
          return
        }
        if ([500].includes(error.response?.data?.statusCode)) {
          toast({ title: '[Error]: Moralis Web3 provider is down', id: 'error_post' })
          return
        }

        if ([400].includes(error.response?.data?.statusCode)) {
          toast({ title: 'You need to add content first', id: 'error_post' })
          return
        }

        toast({ title: error.message, id: 'error_post' })
      }
    )

  const uploadAssets = useCallback(
    async (files: any) => {
      for (const [_, file] of files.entries()) {
        const reader = new FileReader()

        reader.onload = async () => {
          const blob = await (await fetch(reader.result as any)).blob()
          const fileParsed = new File([blob], file.name)

          const formData = new FormData()
          formData.append('file', fileParsed)

          const result = await loaded(
            async () =>
              (
                await fetchApi('/post/uploadAsset', formData, {
                  headers: {
                    ...getAuthHeaders(accountProfile.address! as address),
                    'Content-Type': 'multipart/form-data',
                  },
                })
              ).path,
            setIsAssetLoading,
            err => toast({ title: `Error while uploading asset ${err}`, status: 'error' })
          )
          if (result !== undefined) {
            setContent(prev => ({ ...prev, images: [...prev.images, result] }))
          }
        }
        reader.readAsDataURL(file)
      }
    },
    [accountProfile.address]
  )

  const upgrade = async (ipfs: IPostSchema['ipfs'], quest: Quest) => {
    let txHash = ''

    if (quest.tokens.length == 0) {
      throw new Error('Quest post must contain rewards')
    }
    const questContractAddress = Address.QUEST
    for (const token of quest.tokens) {
      const allowance = await readContract({
        abi: Abi.ERC20,
        address: token.address as address,
        functionName: 'allowance',
        args: [accountProfile.address, questContractAddress as string],
      })
      if (Number(allowance) < Number(token.amount * 10 ** 18)) {
        const contract = getContract({
          abi: Abi.ERC20,
          address: token.address as address,
          walletClient,
        })
        try {
          const tx = await contract.write?.approve([questContractAddress, Number(token.amount) * Number(10 ** 18)])

          await waitForTransaction({ hash: tx })
          toast({ title: `Approved, tx: ${trimAddress(tx)}`, status: 'info' })
        } catch (e) {
          toast({ title: `Unable to get allowance`, status: 'error' })
          return
        }
      }
    }
    try {
      const timestamp = Date.now()
      const tx = await contracts.quest?.write.startQuest([
        [...quest.tokens.map(t => t.address.toLowerCase())],
        [...quest.tokens.map(t => t.amount * 10 ** 18)],
        timestamp,
      ])
      txHash = tx

      toast({ title: `Executed: ${trimAddress(tx)}`, status: 'info' })
    } catch (e) {
      toast({ title: `Unable to call contract`, status: 'error' })
      return
    }

    await loaded(
      async () => {
        await fetchApi('/post/upgrade', { ...quest, uuid: ipfs, txHash })
        toast({ status: 'success', title: 'Offer added successfully' })
      },
      undefined,
      e => {
        if (e instanceof AxiosError) {
          toast({ status: 'error', title: e?.response?.data.message })
        }
      }
    )
  }

  return { post, isPosting, content, setContent, isAssetLoading, uploadAssets, upgrade }
}

export type { IUsePost, IPostContent, IPostFile }
export { emptyPostContent, emptyPostFile }
