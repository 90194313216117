import {
  Modal,
  ModalContent,
  ModalOverlay,
  ModalBody,
  useDisclosure,
  ModalHeader,
  Flex,
  Box,
  Input,
  Button,
} from '@chakra-ui/react'
import { FC, useEffect, useMemo, useState } from 'react'
import { Text, Image } from '@chakra-ui/react'
import useAsyncEffect from '../../../../../../../../../hooks/effects/async'
import { fetchRoles } from '../../../../../../../../shared/select/utils'
import { loaded } from '../../../../../../../../../utils/process'
import { environment } from '../../../../../../../../../utils/environment'
import { fetcher } from '../../../../../../../../../utils/fetcher'
import { useProfile } from '../../../../../../../../../hooks/useProfile'
import { useAccountProfileProvider } from '../../../../../../../../../provider/AccountProfile/context'
import { useProfileProvider } from '../../../../../../../../../provider/Profile/profileProvider'
import { usePostProvider } from '../../../../../../../../../provider/PostProvider/postProvider'

interface IJoinAsModalProps {
  isOpen: boolean
  onClose: any
  handleJoin: (name: string) => any
}
// TODO: Modal opens with lags
export const JoinAsModal: FC<IJoinAsModalProps> = ({ isOpen, onClose, handleJoin }) => {
  const { quest } = usePostProvider()
  const defaultRoles = quest?.roles
  const [roles, setRoles] = useState<{ name: string; icon: string }[]>([])
  const [role, setRole] = useState('')
  const [commonRoles, setCommonRoles] = useState<string[]>([])
  const data = useProfileProvider()

  useAsyncEffect(async () => {
    await loaded(async () =>
    /* FIXME: TODO: api.candao.io deprecation */
      fetcher(`${environment.CANDAO_API}/api/profession-role`, {
        pagination: {
          page: 1,
          per_page: 999,
        },
      }).then(res => {
        setRoles(res.profession_roles)
      })
    )
  }, [])

  useEffect(() => {
    const commonRoles: string[] = []
    defaultRoles?.forEach(dr => {
      if (data.roles?.includes(dr)) {
        commonRoles.push(dr as string)
      }
    })

    setCommonRoles(commonRoles)
  }, [])

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="2xl"
    >
      <ModalOverlay />
      <ModalContent
        mx="10px"
        marginBottom={{ base: '10px', md: '64px' }}
      >
        <ModalHeader>
          <Text>Join AS</Text>
        </ModalHeader>
        <ModalBody
          p="12px"
          px="26px"
          sx={{ display: 'flex', gap: '12px', flexDirection: 'column' }}
        >
          <Text
            color="backgroundAccent"
            fontSize="14px"
            fontWeight="500"
          >
            Do you want to join the task in role that has been selected by the owner?
          </Text>
          {/* TODO: Check if user have below roles */}
          {defaultRoles?.map(dr => (
            <Role
              key={dr}
              handleJoin={() => handleJoin(dr)}
              name={dr}
              icon={roles.length > 0 ? roles.filter(r => r.name == dr)[0].icon : ''}
              isDisabled={data.roles?.includes(dr)}
            />
          ))}
          <Text
            color="backgroundAccent"
            fontSize="14px"
            fontWeight="500"
          >
            Join a different role
          </Text>
          <Input
            value={role}
            onChange={e => setRole(e.target.value)}
            placeholder="Sarch your role"
          />
          <Flex
            maxHeight="400px"
            overflow="scroll"
            flexDirection="column"
            my="20px"
            gap="4px"
          >
            {roles
              .filter(r => r.name?.toLowerCase().includes(role.toLowerCase()))
              .map((r, idx) => (
                <Role
                  handleJoin={handleJoin}
                  name={r.name}
                  icon={r.icon}
                  key={`${r.name}-${idx}`}
                  isDisabled={data.roles?.includes(r.name)}
                />
              ))}
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

interface IRoleComponent {
  name: string
  icon: string
  handleJoin: (name: string) => any
  isDisabled?: boolean
}

const Role: FC<IRoleComponent> = ({ name, icon, handleJoin, isDisabled = false }) => {
  return (
    <Flex
      borderRadius="8px"
      border="1px solid"
      borderColor="#EDEFF5"
      background="borderPrimary"
      p="12px"
      alignItems="center"
      justifyContent="space-between"
    >
      <Button
        background="backgroundAccent"
        borderRadius="8px"
        px="16px"
        cursor="pointer"
        py="8px"
        isDisabled={isDisabled}
        onClick={() => handleJoin(name)}
        color="textWhite"
      >
        <Text fontSize="12px">Join AS</Text>
      </Button>
      <Box width="80%">
        <Text>{name}</Text>
      </Box>
      <Box
        background="backgroundMain"
        width="28px"
        height="28px"
        borderRadius="full"
        display="grid"
        placeItems="center"
      >
        <Image
          src={icon}
          width="14px"
          height="14px"
        />
      </Box>
    </Flex>
  )
}
